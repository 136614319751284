import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "../../AdminBoard/AdminBoard.css";
// import CustomTabs from '../../components/Tabs/Tabs';
import UserList from "views/UserList/UserList";
import ProfileList from "views/profileList/profileList";
import HelpCenter from "views/HelpCenter/HelpCenter";
import CustomTabs from "components/Tabs/Tabs";
import Overview from "../Overview/Overview";
// import UserList from

const revenueData = [
    {
    tabName: 'OVERVIEW',
    component: Overview,
    // component: HelpCenter,
    location: 'AD_REVENUE',
    key: 1,
  },
  {
    tabName: 'GROWTH',
    component: HelpCenter,
    location: 'AD_REVENUE',
    key: 2
  },
  {
    tabName: 'METRO',
    component: HelpCenter,
    location: 'AD_REVENUE',
    key: 3
  },
  {
    tabName: 'CUSTOMER',
    component: HelpCenter,
    location: 'AD_REVENUE',
    key: 4
  },
  {
    tabName: 'RESOURCES',
    component: HelpCenter,
    location: 'AD_REVENUE',
    key: 5
  },
];

export default function Revenue() {
  useEffect(() => {
    return () => {
    // document.getElementById("Analytics Dashboard").style.backgroundColor = "transparent";
  }},[]);
 
    if (document.getElementById("Analytics Dashboard")) {
        document.getElementById("Analytics Dashboard").style.backgroundColor = "#c54d39";
    }
    return(
        // <div class="admin_container">
        <div class='analytics_dashboard'>
            {/* <div  class="admin_body">
            <div class="admin_card">
               <h4><a href="/app/userlist">User Management</a> </h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/profiles"> Profile Management</a></h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/userMapping"> Profile Mapping</a></h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/setAnnouncements"> Announcements</a></h4>
            </div>
            </div> */}
            <CustomTabs data={revenueData}/>
            {/* <h1>Revenue Tab</h1> */}
        </div>
        )
}