import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from "@material-ui/core/Icon";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import './userMapping.css';
import {hostname} from '../../hostConfiguration.js'


export default function UserMapping() {

    const [usersList, setUserList] = React.useState([]);
    const [ModulesProfile, setModulesProfile] = React.useState([]);
    const [DataProfile, setDataProfile] = React.useState([]);
    const [userValue, setUserValue] = React.useState('');
    const [moduleValue, setModuleValue] = React.useState('');
    const [dataValue, setDataValue] = React.useState('');
    let users = [];
    let formObject = {};

    useEffect(() => {
        fetch(hostname.host+ "/admin/users")
            .then(response => response.json())
            .then(a => {
                users = a.data.map((names) => names.username)
                setUserList(users);
                console.log(a);
            });
    }, []);


    const createMapping = () =>{
        formObject['user'] = userValue;
        formObject['module'] = moduleValue;
        formObject['data'] = dataValue;
        console.log(formObject);

    }
  const defaultProps = {
    options: usersList,
    getOptionLabel: (option) => option,
   };

    return (
    <div>
            {/* <a href='/app/admin'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
        <Card>
        <CardBody>
        {/* <a href='/app/admin'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
        <div class="mappingRow">
        <div style={{ width: 200 }}>
        <Autocomplete
          {...defaultProps}
          id="users"
          value={userValue}
          onChange={(event, newValue) => {
            setUserValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="User" margin="normal" />}
        />
        </div>
        <div style={{ width: 200 }}>
        <Autocomplete
       {...defaultProps}
       id="modules"
       value={moduleValue}
       onChange={(event, newValue) => {
         setModuleValue(newValue);
       }}
       renderInput={(params) => <TextField {...params} label="Module" margin="normal" />}
        />
        </div>
        <div style={{ width: 200 }}>
        <Autocomplete
        {...defaultProps}
        id="data"
        value={dataValue}
        onChange={(event, newValue) => {
          setDataValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Data" margin="normal" />}
        />
        </div>
        </div>
        <Button id="btn_login" onClick={createMapping}>Submit</Button>
        </CardBody>
        </Card>
    </div>)
}