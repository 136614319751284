import React, { useState, useEffect } from "react";

import './login.css';
import loginImage from 'assets/img/login/loginImage.png';
import male from 'assets/img/login/male.svg';

import bluegirl from 'assets/img/login/girl-dark-blue.2cf1b661.svg';
import maleOrange from 'assets/img/login/boy-orange.00daffd9.svg';
import boyteacher from 'assets/img/login/boy-teacher.7cc20ce0.svg';
import boyback from 'assets/img/login/boy-orange-back.dc90ff36.svg';
import greengirl from 'assets/img/login/girl-turquoise.5f13dd6f.svg';
import boyviolet from 'assets/img/login/boy-violet.fe48758d.svg';

import section1 from 'assets/img/login/section1.png';
import section2 from 'assets/img/login/section2.png';
import section3 from 'assets/img/login/section3.png';
import Terms from '../../components/termsOfUse/termsOfUse.js';
import { useHistory } from 'react-router-dom';
import {hostname} from '../../hostConfiguration.js';
import Button from '@material-ui/core/Button';
import logo from "assets/img/login/login_logo.svg";
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import cockpit from 'assets/img/icons/cockpit.svg';
import snapshot from 'assets/img/icons/snapshot-builder.svg';
import navigator from 'assets/img/icons/account-management.svg';
import add from 'assets/img/icons/add.svg';

function Login(props) {
    const [open, setOpen] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const openTerms = () => {
        // console.log('clicked');
        setOpen(true);
    }
    const history = useHistory();
    // let user = {username : 'eric249'};
    const requestAccess = (e) => {
        e.preventDefault();
        let usernames = {'username' : username};
        console.log(usernames);
        fetch(hostname.host + '/auth/request-access', {
            method: "POST",
            headers: {
            'Content-type': 'application/json',
           },
          body: JSON.stringify(usernames)
           })
              .then(response => response.json())
              .then(a => {
                if(!a.error) {
                    console.log(a.serviceNow);
                window.location.href=a.serviceNow;
                }
      
              });
    }
    const handleSnackClose =() =>{
        setSnackbarOpen(false);
    }
    const handleUsername = (event) => {
        console.log(event.target.value);
        setUsername(event.target.value);
    }

    useEffect(() => {
        if(props.location.search && props.location.search == '?logout') {
            console.log('logout');
            setMessage('Logged out Successfully !!');
            setSnackbarOpen(true);
        } else if(props.location.search && props.location.search == '?noAccess') {
            setMessage("Sorry You don't have access to visit the portal, Please Request for Access");
            setSnackbarOpen(true);
        }

    }, []);

    const loginUser =() => {
        // history.push(`/app/home`);
        console.log(Cookies.get('username'));
        let uname = {"username" : Cookies.get('username')}
        fetch(hostname.host + '/auth/login', {
            method: "POST",
            headers: {
            'Content-type': 'application/json',
           },
          body: JSON.stringify(uname)
           })
              .then(response => response.json())
              .then(a => {
                if(!a.error) {
                    Cookies.set('access_token', a.accessToken);
                    Cookies.set('refresh_token', a.refreshToken);
                window.location.href='/app/home'
                console.log('loggedIN');
                }
      
              });
        
    }
    const handleClose= () =>{
        // console.log('closed');
        setOpen(false);
    }
    return (
        <div className="login_container">
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        // autoHideDuration={6000}
        onClose={handleSnackClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
            <div className="login_theme">
                <div className="login_leftside">
                    <div className="login_form">
                        <div className="login_form_block">
                            <div className="logo">
                            <img src={logo} ></img>
                            </div>
                            <p className="welcome">Welcome back! Please login to your account.</p>
                            <form>
                                <div className="username">
                                <input placeholder="Username" onChange={handleUsername}></input>
                                </div>
                                <div className="btn-block">
                                     {/* <button class="btn_login" onClick={loginUser} > */}
                      <button class="btn_login" >
                          <a href="/login" class="login_link" >Login</a>
                          {/* Login */}
                     </button>
                                    <button class="btn_request" onClick={requestAccess}>Request Access</button>
                                </div>
                                <div class="termsDiv"><span class="terms" onClick={openTerms}>Terms of use. Privacy Policy</span></div>
                                <Terms open={open} closeButton={handleClose} />
                            </form>
                        </div>
                    </div>
                    <div className="scroll_suggest">
                        <button class="scroll_btn">
                            <a href="#section2" class="scroll"><i class="arrow down"></i>  </a>
                            <a href="#section2" class="scroll_link"> Scroll Down to Learn More
                            </a>
                        </button>
                    </div>
                </div>
                <div className="login_image">
                    <div className="desktop_image" >
                        <img src={loginImage} alt="theme_image"></img>
                    </div>
                    <div className="persons" >
                        <img class="left_animate" src={maleOrange} alt="theme_image"></img>
                        <img class="right_animate" src={bluegirl} alt="theme_image"></img>
                    </div>
                </div>
            </div>
            <div className="details">
                <div id="section2" className="login_bottom">
                    <div className="features">
                        <h2 className="title">Featured Dashboards that We Provide</h2>
                        <div className="featured_list_container">
                            <ul className="featured_list">
                                <li> <a href="/">
                                    <img className="images_list" src={cockpit} alt="theme_image" />
                                 EXECUTIVE COCKPIT</a></li>
                                <li><a href="/">
                                    <img className="images_list" src={snapshot} alt="snapshot" />
                                 SNAPSHOT BUILDER</a></li>
                                <li><a href="/">
                                    <img className="images_list" src={navigator} alt="navigator" />
                                CUSTOMER NAVIGATOR</a></li>
                                <li><a href="/">
                                    <img className="images_list" src={add} alt="add" />
                                ...AND MORE</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="section">
                            <div className="section_column">
                                <img src={section1} alt="theme_image"></img>
                                <img className="boy_teacher" src={boyteacher} alt="theme_image"></img>
                            </div>


                            <div className="section_column">
                                <h2 className="title">Essential analytics for agile decision-making</h2>
                                <p>Navigate complex decision-making with a single view of all essential and up-to-date metrics, leading to more timely and relevant insights.</p>
                                <button className="learn_more"><a className="learnmore_link">Learn More</a></button>
                            </div>
                        </div>
                        <div className="section">
                            <div className="section_column">
                                <h2 className="title">Mindfully-designed data visualizations that saves time</h2>
                                <p>Dodge the repetitive process of slide production with our built-in templates and pre-loaded data sets, ensuring both speed and quality of your analysis.</p>
                                <button className="learn_more"><a className="learnmore_link">Learn More</a></button>
                            </div>
                            <div className="section_column">
                                <img src={section2} alt="theme_image"></img>
                                <img class="boy_back" src={boyback} alt="theme_image"></img>
                            </div>
                        </div>
                        <div className="section">
                            <div className="section_column">
                                <img src={section3} alt="theme_image"></img>
                                <img class="green_girl" src={greengirl} alt="theme_image"></img>
                                <img class="boy_violet" src={boyviolet} alt="theme_image"></img>
                            </div>

                            <div className="section_column">
                                <h2 className="title">Customer knowledge that produces leverage</h2>
                                <p>Gain leverage with our rich knowledgebase that enables you to seize opportunities, personalize interactions, and reinforce relationships with your customers.</p>
                                <button className="learn_more"><a className="learnmore_link">Learn More</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Login;