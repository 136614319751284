
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import RoutedUser from "views/RoutedUser/RoutedUser.js";
import NewUser from "views/NewUser/NewUser.js";
import Home from "views/Home/Home.js";
import Profiles from "views/profiles/profiles.js";
import UserList from "views/UserList/UserList.js";
import UserMapping from "views/userMapping/userMapping.js";
import SetAnnouncements from "views/setAnnouncements/setAnnouncements.js";
import AdminBoard from "views/AdminBoard/AdminBoard.js";
import  AnalyticsDashboard  from "views/AnalyticsDashboard/AnalyticsDashboard.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import HelpCenter from "views/HelpCenter/HelpCenter.js";
import Settings from "views/Settings/Settings.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout

import snapshot from "assets/img/icons/snapshot-builder.svg";
import settings from "assets/img/icons/self-service.svg";
import account from "assets/img/icons/account-management.svg";
import cockpit from "assets/img/icons/cockpit.svg";
import tools from "assets/img/icons/tools.svg";
import info from "assets/img/icons/info.svg";
import Logout from "assets/img/icons/logout.svg";
import Blocks from "assets/img/icons/blocks.svg";
import Add from "assets/img/icons/add.svg";
import AppMarket from "assets/img/icons/app.svg";
import AdminConsole from "assets/img/icons/adminconsole.svg";
import { Announcement } from "@material-ui/icons";
import RequestAccess from "views/RequestAccess/RequestAccess.js";
import Revenue from "views/AnalyticsDashboard/Revenue/Revenue";

const dashboardRoutes = [
    {
    path: "/home",
    name: "Home",
    icon: Dashboard,
    component: Home,
    layout: "/app"
  },
  {
    path: "/admin",
    name: "Admin Console",
    icon: AdminConsole,
    component: AdminBoard,
    layout: "/app"
  },
  {
    path: "/cockpit",
    name: "Executive Cockpit",
    icon: cockpit,
    component: DashboardPage,
    layout: "/app"
  },
  // {
  //   path: "/requestAccess/:id",
  //   name: "Request Access",
  //   component: RequestAccess,
  //   layout: ""
  // },
  {
    path: "/userlist",
    name: "User List",
    icon: Person,
    component: UserList,
    layout: "/app"
  },
  {
    path: "/profiles",
    name: "Create Profile",
    icon: Person,
    component: Profiles,
    layout: "/app"
  },
  {
    path: "/newuser",
    name: "New User",
    icon: Person,
    component: NewUser,
    layout: "/app"
  },
  {
    path: "/userMapping",
    name: "User Mapping",
    icon: Person,
    component: UserMapping,
    layout: "/app"
  },
  {
    path: "/setAnnouncements",
    name: "Announcements",
    icon: Person,
    component: SetAnnouncements,
    layout: "/app"
  },
  {
    path: "/user/:id",
    name: "Routed User",
    icon: Person,
    component: RoutedUser,
    layout: "/app"
  },
  {
    path: "/snapshot-builder",
    name: "Snapshot Builder",
    icon: snapshot,
    component: HelpCenter,
    layout: "/app"
  },
  {
    path: "/account-navigator",
    name: "Account Navigator",
    icon: account,
    component: HelpCenter,
    layout: "/app"
  },
  {
    path: "/self-service",
    name: "Self-Service Generator",
    icon: settings,
    component: HelpCenter,
    layout: "/app"
  },
  {
    path: "/analytics-dashboard",
    name: "Analytics Dashboards",
    icon: Blocks,
    // component: HelpCenter,
    component: AnalyticsDashboard,
    color: '#c54d39',
    layout: "/app"
  },
  {
    path: "/appMarketplace",
    name: "App Marketplace",
    icon: AppMarket,
    component: HelpCenter,
    layout: "/app"
  },
  {
    path: "/helpcenter",
    name: "Help Center",
    icon: info,
    component: HelpCenter,
    layout: "/app"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: tools,
    component: Settings,
    layout: "/app"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: Logout,
    // component: Settings,
    layout: "/app"
  },
  {
    path: "/revenue",
    name: "Revenue",
    // icon: Person,
    component: Revenue,
    layout: "/analytics-dashboard"
  }
];

export default dashboardRoutes;
