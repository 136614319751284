import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import './profiles.css';
import {hostname} from '../../hostConfiguration.js'





const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    hideModule : {
        visibility: 'hidden'
    },
    showModule: {
        visibility: 'visible'
    }

  }));




export default function Profiles() {

    
      const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };


 const classes = useStyles();
  const [selectedProfile, setProfile] = React.useState('Module');
  const [Module, setModule] = React.useState([]);
  const [selectedObject, setObject] = React.useState([]);
  const [Data, setData] = React.useState([]);
  const [Role, setRole] = React.useState('');
  const [IsModule, setModuleVal] = React.useState(true);
  const [openLink, setOpenLink] = React.useState(false);
  
  const modules = [
    'Executive Cockpit',
    'Snapshot Builder'
  ];
  
  const objects = [
      'Revenue',
      'Bookings'
  ]
  
  const data = [
      'Region',
      'Vertical'
  ]

  useEffect(() => {

    const url = hostname.host + '/admin/profiles';
    fetch(url)
        .then(response => response.json())
        .then(a => {
            // console.log(a.data.user_info[0]);
        });
}, []);

  const createProfile = () =>{
    setOpenLink(true);

  }

  const changeRoleField = (event) => {
    setRole(event.target.value);
  };

  const handleRadioChange = (event) =>{
    setProfile(event.target.value);
    if(event.target.value != 'Module'){
        
    console.log(event.target.value);
        setModuleVal(false);
    } else {
        setModuleVal(true);
    }
    console.log(IsModule);
    }

  const handleModuleChange = (event)=> {
    setModule(event.target.value);
  }

  const handleObjectChange = (event)=> {
    setObject(event.target.value);
  }

  const handleDataChange = (event)=> {
    setData(event.target.value);
  }

    return(
        <div> 
        {/* <a href='/app/admin'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
        <Card>
        <CardBody>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <FormControl className={classes.formControl}>
            <TextField
            label="Role Name"
            id="role_name"
            value = {Role}
            onChange = {changeRoleField}
            required
          />
        </FormControl>
        </GridItem>
        </GridContainer>
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <FormControl id='radio_section'>
                <FormLabel component="legend" id="radio_label">Select the type :</FormLabel>
                <RadioGroup aria-label="quiz" name="quiz" id="radio_group" value = {selectedProfile} onChange={handleRadioChange}>
                <FormControlLabel value="Module" control={<Radio />} label="Object & Module" />
                <FormControlLabel value="Data" control={<Radio />} label="Data" />
                </RadioGroup>
            </FormControl>
        </GridItem>
        </GridContainer>
        <GridContainer id={(IsModule == true) ? 'showModule' : 'hideModule'} >
          <GridItem xs={12} sm={12} md={3}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Select Module</InputLabel>
                <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={Module}
                onChange={handleModuleChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                >
                {modules.map((name) => (
                <MenuItem key={name} value={name}>
                <Checkbox checked={Module.indexOf(name) > -1} />
                <ListItemText primary={name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Select Object</InputLabel>
                <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedObject}
                onChange={handleObjectChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                >
                {objects.map((name) => (
                <MenuItem key={name} value={name}>
                <Checkbox checked={selectedObject.indexOf(name) > -1} />
                <ListItemText primary={name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        
        </GridItem>
        </GridContainer>
        <GridContainer id={(IsModule == false) ? 'showModule' : 'hideModule'}>
        <GridItem xs={12} sm={12} md={4}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">Select Data</InputLabel>
                <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={Data}
                onChange={handleDataChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                >
                {data.map((name) => (
                <MenuItem key={name} value={name}>
                <Checkbox checked={Data.indexOf(name) > -1} />
                <ListItemText primary={name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </GridItem>
        </GridContainer>
        </CardBody>
        <CardFooter>
        <Button id="btn_login" onClick={createProfile}>Submit</Button>
        </CardFooter>
        </Card>
        <div class= {openLink ? "redirect_link" : 'hide_link'}>
      <a href='/app/userMapping'>Create Mapping<Icon id="button_right">keyboard_arrow_right</Icon></a> 
      </div>
      </div>
    )
}