import React, {useState, useEffect} from "react";
import {hostname} from '../../hostConfiguration.js';
import './RequestAccess.css'

export default function RequestAccess(props) {

    const [service, setService] = React.useState('');
    let serv = '';

    useEffect(() => {
        let param = props.location.pathname.split('/')[2];
        let obj = {'username' : param}

        fetch(hostname.host + '/auth/request-access', {
            method: "POST",
            headers: {
            'Content-type': 'application/json',
           },
          body: JSON.stringify(obj)
           })
              .then(response => response.json())
              .then(a => {
                  setService(a.serviceNow);
                  serv = a.serviceNow;
                console.log(a.serviceNow);
              })
    }, []);    

    return (
        <div>
            <h3 class="reqAccHead">Requesting Access</h3>
        <div class='requestAccess'>
            <b><p>Thank you for Requesting the access. Please Click on the below link for more details</p></b>
            <b><a href={service}>Service</a> </b>
        </div>
        </div>
    )
}