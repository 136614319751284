import React, { useState, Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Link, NavLink, Switch, Route, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import {hostname} from './hostConfiguration.js';

// export default function SecuredRoute(props){
    

    const isLoggedIn = () => {
        const refresh_token = Cookies.get('refresh_token');
        const access_token = Cookies.get('access_token');
        // console.log('inside router file');
        if (access_token) {
        //   return <Route exact path={props.path} component={props.component}/>
        return true
        } else {
        //   return <Redirect exact to="/" />
        return false
        }
      }

//     return(
//      {isLoggedIn}
//     )
//   }

//   const  SecuredRoute = ({ Component, ...rest }) => (
    // <Route {...rest} render={props => isLoggedIn() ? (
    //     <Component {...props} />
    // ) : (
    //         <Redirect to='/' />
    // )} />
// )

const SecuredRoute = ({ component: Component, auth, ...rest }) => {
    

        //   console.log("auth:" + auth, Cookies.get('access_token'));
        return(
            <Route {...rest} render={(props) => (
               ( Cookies.get('access_token')? true : false)
                    ? <Component {...props} />
                    : <Redirect to='/' />
            )} />
        )
    }
export default SecuredRoute;