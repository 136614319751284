import React from "react"
import {ReactComponent as TelescopeIcon} from "assets/img/binoculars.svg";
import './ADHomeItems.css';

export default function ADHomeItems() {
    const dashboards = [
        {
            name: 'REVENUE',
            path: 'revenue',
        },
        {
            name: 'BOOKINGS',
            path: 'bookings',
        },
        {
            name: "CROSS BORDER TRADE",
            path: 'cross-border-trade'
        },
        {
            name: 'PRICING',
            path: 'pricing'
        },
        {
            name: 'CUSTOMER COUNT',
            path: 'customer-count'
        },
        {
            name: 'CHANNEL PERFORMANCE',
            path: 'channel-performance',
        },
        {
            name: 'CHURN',
            path: 'churn',
        },
        {
            name: 'NEW LOGOS AND EXTENSIONS',
            path: 'new-logos-and-extensions'
        },
        {
            name: 'PIPELINE',
            path: 'pipeline'
        },
        {
            name: 'ECOSYSTEM',
            path: 'ecosystem'
        },
        {
            name: 'GLOBAL PLATFORM',
            path: 'global-platform'
        }
    ];
    return (
        <div className="container-fluid analytics-home-page">
            <div className="card">
                <div className="card-header">
                    <div className="card-title h5">ALL DASHBOARD</div>
                    <div id="search-container">
                        <input type="text" placeholder="Search..."  maxLength="20" ></input>
                </div>
                </div>
                <div className="card-body">
                    <div className="product-container">
                        <div className="row no-gutters">
                            {
                                dashboards.map(dashboard => (
                                    <div className="mb-3 col-xl-2 col-md-4 col-sm-3 col-2 analytic-dashboard-category">
                                        <a className="dashboard-selection"
                                           href={`/analytics-dashboard/revenue`}>
                                            <TelescopeIcon />
                                            <p className="main-text">{dashboard.name}</p><span className="last-updated">Last Updated 3 days ago</span>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}