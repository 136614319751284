import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "./AdminBoard.css";
import CustomTabs from '../../components/Tabs/Tabs';
import UserList from "views/UserList/UserList";
import ProfileList from "views/profileList/profileList";
import HelpCenter from "views/HelpCenter/HelpCenter";
// import UserList from

const adminData = [
    {
    tabName: 'USERS',
    component: UserList,
    location: 'AB',
    key: 1,
  },
  {
    tabName: 'PROFILES',
    component: ProfileList,
    location: 'AB',
    key: 2
  },
  {
    tabName: 'PUBLISHER',
    component: HelpCenter,
    location: 'AB',
    key: 3
  },
  {
    tabName: 'SITE STATISTICS',
    component: HelpCenter,
    location: 'AB',
    key: 4
  },
];

export default function AdminBoard() {

    return(
        <div class="admin_container">
            {/* <div  class="admin_body">
            <div class="admin_card">
               <h4><a href="/app/userlist">User Management</a> </h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/profiles"> Profile Management</a></h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/userMapping"> Profile Mapping</a></h4>
            </div>
            <div class="admin_card">
                <h4><a href="/app/setAnnouncements"> Announcements</a></h4>
            </div>
            </div> */}
            <CustomTabs data={adminData}/>
        </div>
        )
}