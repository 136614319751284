import React, {useState, useEffect, useLayoutEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from "@material-ui/core/Icon";
import { MDBDataTableV5 } from 'mdbreact';
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import { NavLink as RouterLink } from 'react-router-dom';
import './profileList.css';
import {hostname} from '../../hostConfiguration.js';
import NewProfileDialog from '../newProfileDialog/newProfileDialog.js';
import UserProfile from '../UserProfile/UserProfile';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import { ContactSupportOutlined } from "@material-ui/icons";




const useStyles = makeStyles({
    // table: {
    //   minWidth: 700,
    // },
    userlistContainer: {
        padding: '25px',
        border: '1px solid #D8D8D8',
        borderRadius: '3px',
        margin: '25px',
        position: 'relative'

    },
    heading: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
      fontWeight: 400,
      color: "#748A9D"
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#EFEFEF !important"
    },
    addNew: {
        lineHeight: 2,
        fontWeight: 400,
        backgroundColor: '#C54D39',
        color: 'white',
        borderRadius: '25px',
        width: '118px',
        textAlign: 'center',
        height: '29px',
    },
    outerContainer: {
      display: 'grid',
      gridTemplateColumns: '65% 35%',
      // gridGap: '5%'
    },
    profileInfo_container: {
      padding: '25px',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      margin: '25px',
      marginLeft: '0px !important',
    },
    hideProfile: {
      display: 'none'
    }
    
  });
const StyledTableCell = withStyles((theme) => ({
    head: {
      color: '#748A9D',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


function createData(name, active, created, modified, lastLogin) {
    return { name, active, created, modified, lastLogin };
  }
  
  const rows = [
    createData('Abcd', 'Not Active', '14 December 2020 10:00:00', '14 December 2020 12:30:00', '14 December 2020 21:10:00'),
  ];

export default function ProfileList() {
    const classes = useStyles();
    const [usersList, setUserList] = React.useState([]);
    const [profileName, setProfileName] = React.useState('');
    const [profileType, setProfileType] = React.useState('');
    const [profileRegion, setProfileRegion] = React.useState([]);
    const [profileCountry, setProfileCountry] = React.useState([]);
    const [profileProduct, setProfileProduct] = React.useState([]);
    const [profileModule, setProfileModule] = React.useState([]);
    const [profileDomain, setProfileDomain] = React.useState([]);
    const [profileData, setProfileData] = React.useState([]);
    const [profileCreated, setProfileCreated] = React.useState('');
    const [profileUpdated, setProfileUpdated] = React.useState('');
    const [assignedUsers, setAssignedUsers] = React.useState([]);
    const [unassignedUsers, setUnassignedUsers] = React.useState([]);
    const [profileId, setProfileId] = React.useState('');
    const [openDetail, setOpenDetail] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState('');
    const [filterData, setFilterData] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    let userList =[];
    // let profileTable = [];

  

    const columns = [
      
      {
        Header: 'Profile Name',
        accessor: 'profile_name',
        minWidth: 130,
      sortable: true,
        show: true,
      displayValue: "Profile Name",
      sortMethod: (a, b) => {
        console.log(a.props.children,b.props.children);
        if (a.props.children.toLowerCase() > b.props.children.toLowerCase()) {
          return 1
        }
        if (a.props.children.toLowerCase() < b.props.children.toLowerCase()) {
          return -1
        }
      }
    },
    {
      Header: 'Profile Type',
      accessor: 'profile_type',
      minWidth: 50,
      sortable: true,
        show: true,
      displayValue: 'Profile Type',
    },
    {
      Header: 'Created On',
      accessor: 'created_on',
      minWidth: 90,
      sortable: true,
        show: true,
      displayValue: 'Created On',
    },
    {
      Header: 'Updated On',
      accessor: 'updated_on',
      minWidth: 90,
      sortable: true,
        show: true,
      displayValue: 'Updated On',
    },
  ]

  const originalData = [
    { firstName: "aaaaa", status: "Pending", visits: 155 },
    { firstName: "aabFaa", status: "Pending", visits: 155 },
    { firstName: "adaAAaaa", status: "Approved", visits: 1785 },
    { firstName: "aAaaaa", status: "Approved", visits: 175 },
    { firstName: "adaSaaa", status: "Cancelled", visits: 165 },
    { firstName: "aasaaa", status: "Cancelled", visits: 157 },
    { firstName: "aweaaaaaewea", status: "Approved", visits: 153 },
    { firstName: "aaaaaa", status: "Submitted", visits: 155 },
    { firstName: "aaaeweaa", status: "Pending", visits: 1555 },
    { firstName: "aabFaa", status: "Submitted", visits: 155 },
    { firstName: "adaAAadsdweaa", status: "Approved", visits: 17585 },
    { firstName: "aAaaaa", status: "Approved", visits: 175 }
  ];

  let columnss = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortable: false,
      show: true,
      displayValue: " First Name"
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false,
      show: true,
      displayValue: "Status "
    },
    {
      Header: "Visits",
      accessor: "visits",
      sortable: false,
      show: true,
      displayValue: " Visits "
    }
  ];

  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');
  let nodelist= [];

  const refreshToken = () => {
    let refreshTok = {"refreshToken" : refresh_token};

    fetch(hostname.host + '/auth/refresh-token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json'
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      Cookies.set('access_token', a.accessToken);
      Cookies.set('refresh_token', a.refreshToken);
      window.location.reload();
    })

  }


    useEffect(() => {
    
        fetch(hostname.host + '/admin/profiles', {
              method: "GET",
              headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${access_token}`
             }
             })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
                let dataAll = [];
              let joinArrays = a.data.data_profiles
              .concat(a.data.object_profiles);
              joinArrays.forEach(element => {
                dataAll.push({
                  'profile_name' : <span id={element.profile_id} onClick={onRowClick} >{element.profile_name}</span>,
                  'profile_type' : element['profile_type'] + ' Profile',
                  'created_on'    : element['created_on'],
                  'updated_on'    : element['updated_on'],
                  'profile_id'    : element['profile_id'],
                })
                // element['profile_name'] = <span id={element.profile_id} onClick={onRowClick} >{element.profile_name}</span>
                // console.log(element);
              });
                let d = {
                  'columns' : columns,
                  'rows' : dataAll
                }
                setProfileData(dataAll)
              }

            });
            fetch(hostname.host + '/admin/users', {
              method: "GET",
              headers: {
              'Content-type': 'application/json',
              'Authorization': `Bearer ${access_token}`
              }
             })
                .then(response => response.json())
                .then(a => {
                  if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                    console.log(a.error.status);
                    refreshToken();
                  } else {
                    userList = a.data;
                    setUserList(a.data);
                  }
                })  
    },[]);

    // useLayoutEffect(() => {
    //   console.log(document.querySelectorAll('#profileListTable table tbody td'));
    // })

    const closeDetail = () => {
      setOpenDetail(false);
      setSelectedRow(null);
    }

    const handleSearch = (event) =>{
      let  searchInput  = event.target.value;
      let filteredData = profileData.filter(value => {
        return (
          value.profile_name.props.children.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.profile_type.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.created_on
            // .toString()
            // .toLowerCase()
            .includes(searchInput) ||
            value.updated_on
            // .toString()
            // .toLowerCase()
            .includes(searchInput)
        );
      });
      setFilterData(filteredData)
    }
    
    const onRowClick = (event) =>{
      console.log('clicked');
      // let rowId = profileData.rows.filter((e) => e.profile_name == event.target.innerHTML)[0].profile_id;
      let rowId = event.target.id;
      
      // Cookies.set('selProfile', rowId)
      // console.log(rowId, profileData.rows.filter((e) => e.profile_name == event.target.innerHTML)[0]);
      let regions = [];
      let countries = [];
      let products = [];
      let modules =[];
      let domains =[];
      setOpenDetail(true);
      fetch(hostname.host + '/admin/profiles/' + rowId , {
        method: "GET",
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${access_token}`
       }
       })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
              setProfileId(a.data.profile_id);
              setProfileName(a.data.profile_name);
              setProfileType(a.data.profile_type);
              setProfileCreated(a.data.created_on);
              setProfileUpdated(a.data.updated_on);
              a.data.accesses.forEach((ele) => {
                let acessObj = ele.access_category;
            
                switch (acessObj) {
                  case 'Deploy Region':
                    regions.push(ele.access_value);
                    break;
                  case 'Deploy Country':
                    countries.push(ele.access_value);
                    break;
                  case 'Product Type':
                    products.push(ele.access_value);
                    break;
                  case 'Module':
                    modules.push(ele.access_value);
                    break;
                  case 'Domain':
                    domains.push(ele.access_value);
                  break;
                  
                }
              })
              setProfileRegion(regions);
              setProfileProduct(products);
              setProfileCountry(countries);
              setProfileDomain(domains);
              setProfileModule(modules);
            }
            let asgnd = [];
            let unasgnd = [];
            userList.forEach((e) => {
              
              let obj = e.object_profiles.map((e) => e.profile_id);
              if(e.data_profile) { obj.push(e.data_profile.profile_id) };
              if(obj.includes(parseInt(rowId))) {
                asgnd.push(e.username);
              } else {
                unasgnd.push(e.username);
              }
            })
            setAssignedUsers(asgnd);
            setUnassignedUsers(unasgnd);
            // console.log(userList);
            });

        // fetch(hostname.host + '/refresh_token' , {
        //       method: "POST",
        //       headers: {
        //       'Content-type': 'application/json',
        //       'Authorization': `Bearer ${refresh_token}`,
        //      }
        //      })
      // console.log(regions, products, countries, domains,modules);

    }
  
    return (
    <div className={classes.container}>
        {/* <a href='/app/admin'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
        <div className={openDetail ? classes.outerContainer : ''}>
        <div className={classes.userlistContainer} id="profileListTable">
         <div className={classes.heading}>
           <h4 className={classes.title}>ALL PROFILES</h4>
           <NewProfileDialog  />
         </div>
         {/* <MDBDataTableV5 hover 
         data={profileData} 
         paging={false}
         searchTop 
         searchBottom={false} /> */}
        <div class="search_filter">
        <TextField
                  variant="outlined"
                  onChange = {handleSearch}
                  id= "profile_search"
                  placeholder= 'search'
                  // value={searchInput}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
        </div>
        <ReactTable
          data={filterData.length > 0 ? filterData : profileData}
          columns={columns}
          // defaultPageSize={10}
          showPagination= {false}
          className="-highlight"
          minRows={0}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  setSelectedRow(rowInfo.index);
                },
                style: {
                  background: rowInfo.index === selectedRow ? 'rgb(251, 244, 242)' : 'white',
                  // color: rowInfo.index === selectedRow ? 'white' : 'black'
                }
              }
            }else{
              return {}
            }
          }}
        />
         {/* <table>
           <thead>
            {columns.map((e)=>(
              <th>{e.label}</th>
            ))}
           </thead>
           <tbody>
              {(filterData.length > 0 ? filterData : profileData).map((e) => (
                <tr>
                  <td>{e.profile_name}</td>
                  <td>{e.profile_type}</td>
                  <td>{e.created_on}</td>
                  <td>{e.updated_on}</td>
                </tr>
              ))}
           </tbody>
         </table> */}
          
        </div>
    {/* <div> */}
    {  
    // document.querySelectorAll('#profileListTable table tbody tr').forEach((l) => {console.log(l)})
    // console.log(document.querySelectorAll('#profileListTable table tbody tr'), document.querySelectorAll('#profileListTable table tbody tr').length);

    }
    <div className={openDetail ? classes.profileInfo_container : classes.hideProfile}>
     {/* <div className={classes.heading}>
      <h4 className={classes.title}>PROFILE DETAIL</h4>
      
      <CloseIcon onClick={closeDetail}/>
     </div> */}
      <UserProfile 
      profilename = {profileName}
      profiletype = {profileType}
      profileproducts = {profileProduct.join(', ')}
      profilecountries = {profileCountry.join(', ')}
      profileregions = {profileRegion.join(', ')}
      profilemodules = {profileModule.join(', ')}
      profiledomains = {profileDomain.join(', ')}
      profilecreated = {profileCreated}
      profileupdated = {profileUpdated}
      profileid = {profileId}
      closeDiv = {closeDetail}
      assigned = {assignedUsers}
      unassigned = {unassignedUsers}
      allUsers = {usersList}
      />
    
    </div>
    </div>
            {
            //  console.log(document.querySelectorAll('#profileListTable table tbody td:first-child'))
            // nodelist = document.querySelectorAll('#profileListTable table tbody td:first-child')
            //   document.querySelectorAll('#profileListTable table tbody td:first-child').forEach(element => {
            //    element.addEventListener('click', onRowClick)
            //  })
            
            }

    </div>
  );
}