import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
// import back from "assets/img/icons/backButton.svg";
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [breadcrumbs, setBreadcrumbs] = React.useState({});
  const componentRef = useRef(null);

  const classes = useStyles();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name =  prop.name;
      } 
      // else if (window.location.href.indexOf('/user/') !== -1) {
      //   name = 'User Info';
      // }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  useEffect(() => {
    if(window.location.href.indexOf('/analytics-dashboard') !== -1) {
      console.log('inside analtics');
      setBreadcrumbs({
        text : 'ANALYTICS DASHBOARD',
        link : '/app/analytics-dashboard'
      });
      document.getElementById('headerContainer').style.backgroundColor = '#c54d39';
      document.getElementsByClassName('ant-tabs-nav-wrap')[0].style.backgroundColor = '#c54d39';
      // document.getElementsByClassName('ant-tabs-tab-active')[0].style.backgroundColor = '#df6955'
    } else {
      document.getElementById('headerContainer').style.backgroundColor = '#818e94';
      if ( document.getElementsByClassName('ant-tabs-nav-wrap')[0]) {
        document.getElementsByClassName('ant-tabs-nav-wrap')[0].style.backgroundColor = '#818e94';
      }
      // document.getElementsByClassName('ant-tabs-tab-active')[0].style.backgroundColor = '#a3b0b7'
    }
  }, [])

  const updateDOM = (el) =>{
    if(window.location.href.indexOf('/analytics-dashboard') !== -1) {
      document.getElementById('headerContainer').style.backgroundColor = '#c54d39';
      document.getElementById('breadcrumbLink').innerHTML = 'ANALYTICS DASHBOARD';
      if ( document.getElementsByClassName('ant-tabs-nav-wrap')[0]) {
      document.getElementsByClassName('ant-tabs-nav-wrap')[0].style.backgroundColor = '#c54d39';
      }
      // document.getElementsByClassName('ant-tabs-tab-active')[0].style.backgroundColor = '#df6955'
    } else {
      document.getElementById('headerContainer').style.backgroundColor = '#818e94';
      document.getElementById('breadcrumbLink').innerHTML = '';
      document.getElementById('breadcrumbs_back').innerHTML = '';
      document.getElementById('breadcrumbs_param').innerHTML = '';
      
      if ( document.getElementsByClassName('ant-tabs-nav-wrap')[0]) {
      document.getElementsByClassName('ant-tabs-nav-wrap')[0].style.backgroundColor = '#818e94';
      }
      // document.querySelector('.ant-tabs-tab .ant-tabs-tab-active')[0].style.backgroundColor = '#a3b0b7';
    }
    if (window.location.href.indexOf('/admin') !== -1) {
      document.getElementById('AppContent').style.marginTop = '101px';
    } else {
      document.getElementById('AppContent').style.marginTop = '121px';
    }
   
  }
 

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container} id='headerContainer'>
        <div className={classes.flex}>
          <div className={classes.breadcrumbs} id='breadcrumbs'>
            <span id='breadcrumbs_back'></span>
            <span id="breadcrumbs_text" className={classes.breadcrumbsText}>
                <span><a href={breadcrumbs.link} className={classes.breadcrumbLink} id='breadcrumbLink' ref={updateDOM}> {breadcrumbs.text}</a></span>
                <span id="breadcrumbs_param"></span>
              </span>
            </div>
          <h4 className={classes.heading}>
            {makeBrand()}
          </h4>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
