import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './NewUser.css';
import {hostname} from '../../hostConfiguration.js'




const useStyles = makeStyles((theme)=> ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 182,
        marginTop: 28
      },
      cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
    
}));
export default function NewUser() {
    const classes = useStyles();
    let formData = {};
    const [profile, setProfile] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openLink, setOpenLink] = React.useState(false);
    const [message, setMessage] = React.useState('');

    useEffect(() => {

        const url = hostname.host + '/admin/profiles';
        fetch(url)
            .then(response => response.json())
            .then(a => {
                const profiles = a.data;
                setProfile(profiles);
            });
    }, []);    

    const createUser = () => {
        const userProfile = document.getElementById('user_profile').innerHTML;
        const selectedProfile = profile.filter((e) => e.profile_name == userProfile)[0].id

        setOpenLink(true);
        console.log(formData);


        fetch(hostname.host + '/admin/users' , {
         method: "POST",
         headers: {
         'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
        })
        .then((result) => result.json())
        .then((info) => { 
            console.log(info.response.alert.message);
        setMessage(info.response.alert.message);
        setOpen(true);
        setOpenLink(true);
    
    })
    };

    const changeField = event => {
        formData[event.target.id] = event.target.value
        console.log(event.target.value);
        console.log(event.target.id);
        console.log(event);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

return(
    <div> 
    {/* <a href='/app/userlist'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
    <Card>
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="Username"
            id="username"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="Display Name"
            id="display_name"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="Email address"
            id="email"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
      </GridContainer>
      <GridContainer>
      
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="First Name"
            id="first_name"
            onChange = {changeField}
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="Last Name"
            id="last_name"
            onChange = {changeField}
            
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl}>
          <TextField
            label="Department"
            id="department"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
      </GridContainer>
      <GridContainer>
      </GridContainer>
    </CardBody>
    <CardFooter>
      <Button id="btn_login" onClick={createUser}>Create User</Button>
    </CardFooter>
  </Card>
  <Snackbar
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div class= {openLink ? "redirect_link" : 'hide_link'}>
      <a href='/app/profiles'>Create Profile<Icon id="button_right">keyboard_arrow_right</Icon></a> 
      </div>
      </div>
);
}