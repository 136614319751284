import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import './Tabs.css';
import UserList from 'views/UserList/UserList';
import ProfileList from 'views/profileList/profileList';
import Cookies from 'js-cookie';
import { Tab } from '@material-ui/core';

const { TabPane } = Tabs;

export default function CustomTabs(props) {
//   const classes = useStyles();
  const [value, setValue] = React.useState('1');

  const handleChange = (event) => {
    // console.log(event);
    // Cookies.remove('adminTab')
    // Cookies.set('adminTab', event);
    // setValue(event);
    switch (props.data[0].location) {
      case 'AD_HOME':
        // Cookies.set('adminTab');
        setValue(event);
        break;
      case 'AB':
        Cookies.set('adminTab', event);
        setValue(event);
        break;
      case 'AD_REVENUE':
        Cookies.set('revenueTab', event);
        setValue(event);
        break;
    }
    // document.querySelector('.ant-tabs-tab.ant-tabs-tab-active').style.backgroundColor = 'yellow';
    // document.querySelectorAll('.ant-tabs-tab').forEach((e) => {
    //   e.style.backgroundColor = '#c54d39';
    // })
    // console.log(document.querySelector(".ant-tabs-tab.ant-tabs-tab-active"));
  };
  useEffect(() => {
    let tabVal;
    setValue(tabVal);
    switch (props.data[0].location) {
      case 'AD_HOME':
        // tabVal = Cookies.get('adminTab');
        setValue('1');
        break;
      case 'AB':
        tabVal = Cookies.get('adminTab');
        setValue(tabVal);
        break;
      case 'AD_REVENUE':
        tabVal = Cookies.get('revenueTab');
        setValue(tabVal);
        break;
    }
  }, [props]);

  const TabComponent = props => <div>{props.data.component}</div>
  return (
      <div>
    <Tabs defaultActiveKey={value} activeKey={value} onChange={handleChange}>
      {props.data.map((e) => (
          <TabPane tab={e.tabName} key={e.key}>
           < e.component /> 
          </TabPane>
      ))}
    {/* <TabPane tab="USERS" key="1">
      <UserList />
    </TabPane>
    <TabPane tab="PROFILES" key="2">
     <ProfileList />
    </TabPane>
    <TabPane tab="PUBLISHER" key="3">
      
    </TabPane>
    <TabPane tab="SITE STATISTICS" key="4">
      
    </TabPane> */}
  </Tabs>
  </div>
  );
}
