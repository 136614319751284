import React, {useState, useEffect, useRef} from "react";
import { Outlet } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import props from 'prop-types';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Icon from "@material-ui/core/Icon";
import {hostname} from '../../hostConfiguration.js';
import PropTypes from "prop-types";
import AssignUsers from "../AssignUsers/AssignUsers";
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './UserProfile.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';




const useStyles = makeStyles({
    container: {
        // padding: "13%"
    },
    heading: {
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between"
  },
  title: {
    fontWeight: 400,
  },
    profileInfo: {
        padding: "5%",
        marginTop: "3%",
        backgroundColor: "white",
        position: "relative"
    },
    row: {
        padding: "1%",
        marginBottom: "17px",
        display: 'grid',
        gridTemplateColumns: '45% 55%'
    },
    label: {
        fontWeight: 600,
        fontSize: '12px',
    },
    buttons: {
        float: "right",
        marginTop: "2%"
    },
    button: {
        margin: 10,
    },
    value: {
        // position: "absolute",
        // left: "50%",
    },
    showObject : {
      display: 'block'
    },
    hideObject: {
      display: 'none',
    },
    buttonsRow: {
      display: 'flex',
      float: 'right',
    },
    editButtons: {
      display: 'none'
    },
    enableEdit: {
      display: 'none',
    },
    input_fields : {
      display: 'none'
    },
    inputs_position : {
      // position: "absolute",
      //   left: "45%",
    },
    paper: {
      width: 170,
      height: 280,
      overflow: 'auto',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: 'none'
    },

  });

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

const UserProfile = ({
    text,
    type,
    placeholder,
    children,
    ...props
  }) => {
    const classes = useStyles();
    const [isEditing, setEditing] = useState(false);
    const handleKeyDown = (event, type) => {
    };
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [profilename, setProfilename] = React.useState(props.profilename);
    const [profileType, setProfileType] = React.useState(props.profiletype)
    const [enableEdit, setEnableEdit] = React.useState(false);
    const [profile, setProfile] = React.useState({});
    const [message, setMessage] = React.useState('Loading ...');
    const [modules, setModules] = React.useState([]);
    const [domain, setDomain] = React.useState([]);
    const [region,setRegion] = React.useState([]);
    const [country, setCountry] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const [selectedModule, setSelectedModule] = React.useState(props.profilemodules.split(', '));
    const [selectedDomain, setSelectedDomain] = React.useState([]);
    const [selectedRegion,setSelectedRegion] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState([]);
    const [openAssignUserDialog, setOpenAssignUserDialog] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    let formData = {};
    const componentRef = useRef(null);

    const refresh_token = Cookies.get('refresh_token');
    const access_token = Cookies.get('access_token');

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
  
    const refreshToken = () => {
      let refreshTok = {"refreshToken" : refresh_token};

      fetch(hostname.host + '/auth/refresh-token' , {
        method: "POST",
        headers: {
        'Content-type': 'application/json',
        },
        body: JSON.stringify(refreshTok)
      }).then(response => response.json())
      .then(a => {
        console.log(a.data);
        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
        window.location.reload();
      })
  
    }
  
    // const checkExpiry = () => {
    //   let dateNow = new Date();
    //   if (access_expiry < dateNow.getTime()) {
    //     refreshToken();
    //   }
    // }
  

    useEffect(() => {
        console.log(props.profileRow, props.profileid);
        setEnableEdit(false);
        setProfile(props.profileRow);
        fetch(hostname.host + '/admin/access_objects', {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
         }
         })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            const modules = a.data.filter((e) => e.access_object_type == 'Module');
            const domain = a.data.filter((e) => e.access_object_type == 'Domain');
            setModules(modules);
            setDomain(domain);
          }
        });
        fetch(hostname.host + '/admin/filters', {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
         }
         })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            const regions = a.data.filter((e) => e.filter_name == 'Region');
            const countries = a.data.filter((e) => e.filter_name == 'Country');
            const products = a.data.filter((e) => e.filter_name == 'Product');
            setRegion(regions);
            setCountry(countries);
            setProduct(products);
            console.log(regions, countries);
          }
        });
        // fetch(hostname.host + '/refresh_token' , {
        //   method: "POST",
        //   headers: {
        //   'Content-type': 'application/json',
        //   'Authorization': `Bearer ${refresh_token}`,
        //  }
        //  })
    }, [props.profileid]);

    const openAssignUsers = () => {
      
      setOpenAssignUserDialog(true);
      setLeft(props.unassigned);
      setRight(props.assigned);
    };
    const closeAssignUsers = () => {
      setOpenAssignUserDialog(false);
    }
    const handleToggle = (value) => () => {
      if (document.getElementById("transferlistItem"+ value).style.backgroundColor == "rgb(251, 244, 242)"){
        document.getElementById("transferlistItem"+ value).style.backgroundColor = "";
      }else document.getElementById("transferlistItem"+ value).style.backgroundColor = "#fbf4f2";
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };

    const handleAllRight = () => {
      setRight(right.concat(left));
      setLeft([]);
    };
  
    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
    };

    const updateDOM = (el) => {
      componentRef.current = el;

      let longest = (props.assigned.concat(props.unassigned)).reduce(
      function (a, b) {
        return a.length > b.length ? a : b;
      }
      );
    let divs = document.getElementsByClassName('assignUserTransferList');
      for (var i = 0; i < divs.length; i++) {
      divs[i].style.width=((longest.length)+'ch');
    }
      
    }

    const  customList = (items, heading) => (
      <div >
        
        <p class="assignUserHeading">{heading}</p>
      <Paper  className={classes.paper}>
        <List dense component="div" role="list" class="assignUserTransferList" ref={updateDOM}>
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
  
            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)}  id={"transferlistItem"+ value}>
                {/* <ListItemIcon class='checkboxIcon'>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    class="checkboxImg"
                  />
                </ListItemIcon> */}
                <ListItemText id={labelId} primary={value} class='transferListLabel' />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
      </div>
    );


    const editProfile = () => {
      console.log(props.profilemodules.split(', '));
      setEnableEdit(true);
      setProfilename(props.profilename);
      setProfileType(props.profiletype);
      setSelectedModule(props.profilemodules ? (props.profilemodules.split(', ')) : []);
      setSelectedDomain(props.profiledomains ? (props.profiledomains.split(', ')) : []);
      setSelectedRegion(props.profileregions ? (props.profileregions.split(', ')) : []);
      setSelectedCountry(props.profilecountries ? (props.profilecountries.split(', ')): []);
      setSelectedProduct(props.profileproducts ? (props.profileproducts.split(', ')) : []);
    }

    const deleteProfile = () => {
      console.log(props.profilename, props.profileid);
      // checkExpiry();
      fetch(hostname.host + "/admin/profiles/" + props.profileid , {
        method: "DELETE",
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
       }
      //  body: JSON.stringify('delete method')
       })
       .then((result) => result.json())
       .then((a) => { 
        if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
          console.log(a.error.status);
          refreshToken();
        } else {
          setMessage('Deleted profile Succesfully');

          setOpen(true);
          window.location.reload();
        }
       })
       .catch(error => {
        console.log(error);
        setMessage("Unable to Delete Profile"); 
        
        setOpen(true); 
      });

  //  fetch(hostname.host + '/refresh_token' , {
  //       method: "POST",
  //       headers: {
  //       'Content-type': 'application/json',
  //       'Authorization': `Bearer ${refresh_token}`,
  //      }
  //      })
      };

    const changeField = (event) => {
      setProfilename(event.target.value);
    }
      
    // const handleClick = () => {
    //     setOpen(true);
    //     fetch(hostname.host + "/admin/users" , {
    //      method: "POST",
    //      headers: {
    //      'Content-type': 'application/json'
    //     },
    //     body: JSON.stringify('post method')
    //     })
    //     .then((result) => result.json())
    //     .then((info) => { console.log(info); })
    // };
    const saveChanges =() => {
      let moduleData = [];
      let domainData = [];
      let productData = [];
      let regionData = [];
      let countryData = [];
      // checkExpiry();
      for (let a =0; a < selectedModule.length; a++) {
        modules.forEach((i) =>{ 
          if(i.access_object_name == selectedModule[a]) {
            moduleData.push(i.access_object_id);
          }
        });
      }
      for (let a =0; a < selectedDomain.length; a++) {
        domain.forEach((i) =>{ 
          if(i.access_object_name == selectedDomain[a]) {
            domainData.push(i.access_object_id);
          }
        });
      }
      for (let a =0; a < selectedRegion.length; a++) {
        region.forEach((i) =>{ 
          if(i.filter_value == selectedRegion[a]) {
            regionData.push(i.filter_id);
          }
        });
      }
      for (let a =0; a < selectedCountry.length; a++) {
        country.forEach((i) =>{ 
          if(i.filter_value == selectedCountry[a]) {
            countryData.push(i.filter_id);
          }
        });
      }
      for (let a =0; a < selectedProduct.length; a++) {
        product.forEach((i) =>{ 
          if(i.filter_value == selectedProduct[a]) {
            productData.push(i.filter_id);
          }
        });
      }
      formData['profile_name'] = profilename;
      // formData['profile_type'] = profileType;
      if (profileType == 'Object') {
        formData['access_objects'] = moduleData.concat(domainData);
        formData['filters'] =[];
      } else {
        formData['access_objects'] = [];
        formData['filters'] = regionData.concat(countryData, productData);
      }
    console.log(formData);
    setEnableEdit(false);
    setDialogOpen(false);
    fetch(hostname.host + '/admin/profiles/' + props.profileid, {
      method: "PUT",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${access_token}`,
     },
     body: JSON.stringify(formData)
     })
     .then((result) => result.json())
     .then((a) => { 
      if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
        console.log(a.error.status);
        refreshToken();
      } else {
         console.log(a.response.alert.message);
         setMessage(a.response.alert.message);
         
         setOpen(true); 
         window.location.reload();
      }
     })
     .catch(error => {
       console.log(error);
       setMessage("Unable to update profile"); 
       
       setOpen(true); 
     });

  // fetch(hostname.host + '/refresh_token' , {
  //     method: "POST",
  //     headers: {
  //     'Content-type': 'application/json',
  //     'Authorization': `Bearer ${refresh_token}`,
  //    }
  //    })
     } 

    const saveChangesDialog = () => {
      setDialogOpen(true);
    }

    const handleRegionChange = (values) => {

      let region_ids = [];
      region.forEach((e) => {
        if (values.includes(e.filter_value)){
          region_ids.push(e.filter_id);
        }
      })
      console.log(region_ids);

      let regions = { "parent_filters" : region_ids};
      fetch(hostname.host + "/admin/subfilters" , {
        method: "POST",
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${access_token}`

       },
       body: JSON.stringify(regions)
       })
       .then((result) => result.json())
       .then((info) => { 
         if(!info.error) {
           console.log(info);
           setCountry(info.data);
         }
        })
    }

    const saveAssignChanges = () => {
      let allUsers = props.allUsers;
      let rightUsers = allUsers.filter((e) => right.includes(e.username)).map((e) => e.user_id);
      let assObj = {"userIds": rightUsers, "profileId": parseInt(props.profileid)};
      console.log( assObj);
        fetch(hostname.host + "/admin/user_profile" , {
         method: "POST",
         headers: {
         'Content-type': 'application/json',
         'Authorization': `Bearer ${access_token}`

        },
        body: JSON.stringify(assObj)
        })
        .then((result) => result.json())
        .then((info) => { 
          if(!info.error) {
            window.location.reload();
          }
         })
    }

    const changeType = (event) => {
      let selectedVal = event.target.value;
      console.log(selectedVal);
      switch(event.target.name) {
        case 'profile_type':
            setProfileType(selectedVal);
            break;
        case 'module_access':
            setSelectedModule(selectedVal);
            break;
        case 'domain_access':
            setSelectedDomain(selectedVal);
            break;
        case 'region_access':
            setSelectedRegion(selectedVal);
            if(selectedVal.length == 0) {
              console.log('empty');
              setSelectedCountry([]);
            }
            handleRegionChange(selectedVal);
            break;
        case 'product_access':
            setSelectedProduct(selectedVal);
            break;
        case 'country_access':
            setSelectedCountry(selectedVal);
            break;
    }
  }
  const cancelDialog = () => {
    setDialogOpen(false);
  }

  const closeDetailView = () => {
    props.closeDiv();
    setEnableEdit(false);
  }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    return (
        <section {...props}>
        <div className={classes.heading}>
      <h4 className={classes.title}>PROFILE DETAILS</h4>
      
      <CloseIcon onClick={closeDetailView}/>
     </div>
          <div
            // onClick={() => setEditing(true)} 
          >

              <div className={classes.container}>
            <div className={classes.profileInfo}>
            <div className={classes.row}>
                <label className={classes.label}>Profile Name<span className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profilename}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  // id={enableEdit ? '' : 'input_fields'}
                  onChange = {changeField}
                  value={profilename}
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Profile Type<span className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profiletype}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
          // labelId="Data Profile"
          name="profile_type"
          value={profileType}
          onChange={changeType}
          disabled
          // readOnly
        >
          <MenuItem value= 'Object'>Object Profile</MenuItem>
          <MenuItem value= 'Data'>Data Profile</MenuItem>
        </Select>
                </span>
            </div>
          <div className={enableEdit ? (profileType == 'Object' ? classes.showObject : classes.hideObject) : (props.profiletype == 'Object' ? classes.showObject : classes.hideObject)}>
            <div className={classes.row}>
                <label className={classes.label}>Module Access</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profilemodules}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
                labelId="Module Access"
                // id="input_fields"
                name="module_access"
                multiple
                value={selectedModule}
                onChange={changeType}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                >
          {modules.map((i) => (
          <MenuItem key={i.access_object_name} value={i.access_object_name}>
              <Checkbox checked={selectedModule.indexOf(i.access_object_name) > -1} />
              <ListItemText primary={i.access_object_name} />
            </MenuItem>
          ))}
        </Select>
        </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Domain Access</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profiledomains}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
          labelId="Domain Access"
          id="input_fields"
          name= "domain_access"
          multiple
          value={selectedDomain}
          onChange={changeType}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {domain.map((i) => (
          <MenuItem key={i.access_object_name} value={i.access_object_name}>
              <Checkbox checked={selectedDomain.indexOf(i.access_object_name) > -1} />
              <ListItemText primary={i.access_object_name} />
            </MenuItem>
          ))}
        </Select>
        </span>
            </div>
          </div>
         <div className={enableEdit ? ( profileType == 'Object' ? classes.hideObject : classes.showObject) : ( props.profiletype == 'Object' ? classes.hideObject : classes.showObject)}>
            <div className={classes.row}>
                <label className={classes.label}>Region Access</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profileregions}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
          labelId="Region Access"
          id="input_fields"
          name="region_access"
          multiple
          value={selectedRegion}
          onChange={changeType}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {region.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedRegion.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Country Access</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profilecountries}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
          labelId="Country Access"
          id="input_fields"
          name = "country_access"
          multiple
          value={selectedCountry}
          onChange={changeType}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {country.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedCountry.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Product Access</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.profileproducts}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
          labelId="Product Access"
          id="input_fields"
          name="product_access"
          multiple
          value={selectedProduct}
          onChange={changeType}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {product.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedProduct.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </span>
            </div>
        </div>
            <div className={classes.row} id="SystemCapturedInfo">
                <label className={classes.label}>Created On</label>
                <span  className={classes.value}>{props.profilecreated}</span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Updated On</label>
                <span  className={classes.value}>{props.profileupdated}</span>
            </div>
            </div>
            </div>
          </div>
        
        <div className={enableEdit ? classes.editButtons :classes.buttonsRow}>
          {/* <AssignUsers  profileName = {props.profilename} profileId = {props.profileid} profiletype = {props.profiletype}/> */}
          <button onClick= {openAssignUsers} class="assignUser">ASSIGN USERS</button>
        <Dialog id="newUserDialog" open={openAssignUserDialog} onClose={closeAssignUsers} aria-labelledby="form-dialog-title" >
        <DialogTitle id="newUserForm">{props.profilename}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
      <Grid container spacing={2}  alignItems="center" className={classes.root}>
      <Grid item>{customList(left, 'Unassigned Users')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, 'Assigned Users')}</Grid>
    </Grid>
    </DialogContent>
        <DialogActions>
          <Button onClick={closeAssignUsers} color="primary" id="sideButton">
            Cancel
          </Button>
          <Button  color="primary" id="createNew" onClick={saveAssignChanges}>
            Save
          </Button>
        </DialogActions>
      
    </Dialog>
          <Button  color="primary" id="sideButton"  onClick={deleteProfile}>
            Delete Profile
          </Button>
          <Button  color="primary" id="createNew" onClick={editProfile}>
            Edit
          </Button>
        </div>
        <div className={enableEdit ? classes.buttonsRow :classes.editButtons}>
          <Button variant="outlined" color="primary" id="createNew" onClick={saveChanges}>
          Save Changes
      </Button>
      <Dialog aria-labelledby="customized-dialog-title" open={dialogOpen}>
        <DialogContent dividers>
          <Typography gutterBottom>
          ARE YOU SURE YOU WANT TO SAVE THE CHANGES MADE?
          </Typography>
         
        </DialogContent>
        <DialogActions>
        <Button  onClick={cancelDialog} >
            Cancel
          </Button>
          <Button autoFocus onClick={saveChanges} id="createNew" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

        </div>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </section>
    );
  };

export default UserProfile;

UserProfile.propTypes = {
  // profileRow: PropTypes.object
    
  };
  
