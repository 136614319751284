import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

function Linechart() {
  useLayoutEffect(() => {

/* Create chart instance */
var chart = am4core.create("linechart", am4charts.XYChart);
chart.fontFamily =  'Montserrat-Medium';
chart.fontSize = 10;
/* Add data */
chart.data = [{
  "year": "1994",
  "cars": 187,
  "motorcycles": 150,
  "bicycles": 121,
  "firstbulletDisabled":false
}, {
  "year": "1998",
  "cars": 260,
  "motorcycles": 199,
  "bicycles": 105
}, {
  "year": "2002",
  "cars": 275,
  "motorcycles": 164,
  "bicycles": 97
}, {
  "year": "2003",
  "cars": 246,
  "motorcycles": 148,
  "bicycles": 93
},{
  "year": "2012",
  "cars": 159,
  "motorcycles": 127,
  "bicycles": 71,
  "bulletDisabled":false
}];

chart.paddingRight = 30;
chart.maskBullets = true;
chart.legend = new am4charts.Legend();
chart.legend.position = "top";
chart.legend.contentAlign = "left";
// chart.legend.fontSize = 10;
chart.legend.paddingBottom = 30;
chart.legend.paddingTop = 2;
chart.legend.fontFamily = 'Montserrat';
chart.legend.useDefaultMarker = true;
let marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
// marker.strokeWidth = 2;
// marker.strokeOpacity = 1;
// marker.stroke = am4core.color("#ccc");
let markerTemplate = chart.legend.markers.template;
markerTemplate.width = 12;
markerTemplate.height = 12;

/* Create axes */
var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "year";
// categoryAxis.maxRightSelf = 25;
// categoryAxis.maxColumns= 15;
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 20;
categoryAxis.renderer.grid.template.disabled = true;
// categoryAxis.fontFamily = 'Montserrat-Medium';
// categoryAxis.fontSize = 10;


/* Create value axis */
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.grid.template.disabled = true;
valueAxis.max = 300;
// valueAxis.fontSize = 10;
// valueAxis.fontFamily = 'Montserrat-Medium';
//To disable y-axis
valueAxis.renderer.labels.template.disabled = true;

/* Create series */
var series1 = chart.series.push(new am4charts.LineSeries());
series1.dataFields.valueY = "cars";
series1.dataFields.categoryX = "year";
series1.name = "AMER";
series1.fill = '#CD503C';
series1.stroke = '#CD503C';
series1.strokeWidth = 2;
series1.strokeLinecap = "square"
var segment = series1.segments.template;
segment.interactionsEnabled = true;

var hoverState = segment.states.create("hover");
hoverState.properties.strokeWidth = 5;
// series1.tensionX = 0.7;
// series1.bullets.push(new am4charts.CircleBullet());
// series1.tooltipText = "{valueY}";
var circleBullet = series1.bullets.push(new am4charts.CircleBullet());
// circleBullet.circle.stroke = am4core.color("#fff");
// circleBullet.circle.strokeWidth = 2;
circleBullet.tooltipText = "Value: [bold]{valueY}[/]";
// circleBullet.circle.fill = am4core.color("#fff");

// var range = categoryAxis.createSeriesRange(series1);
// range.category = 1994;
// range.endCategory = 2020;
// range.contents.stroke = am4core.color("#f00");
// range.contents.fill = am4core.color("#f00");
// range.contents.fillOpacity = 0.5;


var series2 = chart.series.push(new am4charts.LineSeries());
series2.dataFields.valueY = "motorcycles";
series2.dataFields.categoryX = "year";
series2.name = "EMEA";
series2.fill = '#DC7431';
series2.stroke = '#DC7431';
series2.strokeWidth = 2;
var segment = series2.segments.template;
segment.interactionsEnabled = true;

var hoverState = segment.states.create("hover");
hoverState.properties.strokeWidth = 5;
// series2.tensionX = 0.7;
// series2.bullets.push(new am4charts.CircleBullet());
// series2.tooltipText = "{valueY}";
var circleBullet = series2.bullets.push(new am4charts.CircleBullet());
// circleBullet.circle.stroke = am4core.color("#fff");
// circleBullet.circle.strokeWidth = 2;
// circleBullet.circle.fill = am4core.color("#fff");
circleBullet.tooltipText = "Value: [bold]{valueY}[/]";


var series3 = chart.series.push(new am4charts.LineSeries());
series3.dataFields.valueY = "bicycles";
series3.dataFields.categoryX = "year";
series3.name = "APAC";
series3.fill = '#F0CC71';
series3.stroke = '#F0CC71';
series3.strokeWidth = 2;
var segment = series3.segments.template;
segment.interactionsEnabled = true;

var hoverState = segment.states.create("hover");
hoverState.properties.strokeWidth = 5;
// series3.tensionX = 0.7;
// series3.tooltipText = "{valueY}";
// series3.bullets.push(new am4charts.CircleBullet());
var circleBullet = series3.bullets.push(new am4charts.CircleBullet());
// circleBullet.circle.stroke = am4core.color("#fff");
// circleBullet.circle.strokeWidth = 2;
// circleBullet.circle.fill = am4core.color("#fff");
circleBullet.tooltipText = "Value: [bold]{valueY}[/]";


var labelBullet1 = series1.bullets.push(new am4charts.LabelBullet());
labelBullet1.disabled = true;
labelBullet1.propertyFields.disabled = "bulletDisabled";
labelBullet1.label.text = "{valueY}";
//labelBullet1.horizontalCenter = "left";
labelBullet1.label.horizontalCenter = "left";
labelBullet1.label.paddingLeft = 10;

var labelBulletfirst = series1.bullets.push(new am4charts.LabelBullet());
labelBulletfirst.disabled = true;
labelBulletfirst.propertyFields.disabled = "firstbulletDisabled";
labelBulletfirst.label.text = "{valueY}";
//labelBullet1.horizontalCenter = "left";
labelBulletfirst.label.horizontalCenter = "right";
labelBulletfirst.label.paddingRight = 10;


var labelBullet2 = series2.bullets.push(new am4charts.LabelBullet());
labelBullet2.disabled = true;
labelBullet2.propertyFields.disabled = "bulletDisabled";
labelBullet2.label.text = "{valueY.previousChange}";
labelBullet2.label.horizontalCenter = "left";
labelBullet2.label.paddingLeft = 10;

var labelBullet2first = series2.bullets.push(new am4charts.LabelBullet());
labelBullet2first.disabled = true;
labelBullet2first.propertyFields.disabled = "firstbulletDisabled";
labelBullet2first.label.text = "{valueY}";
//labelBullet1.horizontalCenter = "left";
labelBullet2first.label.horizontalCenter = "right";
labelBullet2first.label.paddingRight = 10;

var labelBullet3 = series3.bullets.push(new am4charts.LabelBullet());
labelBullet3.disabled = true;
labelBullet3.propertyFields.disabled = "bulletDisabled";
labelBullet3.label.text = "{valueY.previousChange}";
labelBullet3.label.horizontalCenter = "left";
labelBullet3.label.paddingLeft = 10;

var labelBullet3first = series3.bullets.push(new am4charts.LabelBullet());
labelBullet3first.disabled = true;
labelBullet3first.propertyFields.disabled = "firstbulletDisabled";
labelBullet3first.label.text = "{valueY}";
//labelBullet1.horizontalCenter = "left";
labelBullet3first.label.horizontalCenter = "right";
labelBullet3first.label.paddingRight = 10;

/* Add legend */
// chart.legend = new am4charts.Legend();


}, []);
/* Create a cursor */
// chart.cursor = new am4charts.XYCursor();
    return (
        <div id="linechart" style={{ width: "100%", height: "350px"  }}></div>
      );
}

export default Linechart
