import React, {useEffect, useState} from "react"
import ADHomeItems from '../ADHomeItems/ADHomeItems';
import './Home.css';

export default function Home() {
    
    useEffect(() => {
        document.getElementById('breadcrumbs_param').innerHTML = '  >  HOME';
        
    }, [])

    // document.getElementById('headerContainer').style.backgroundColor = '#c54d39';
    return (
        <div className="container-fluid">
        {/* <div className="card">
            <div className="card-header">
                <div className="card-title h5">
                    ALL DASHBOARDS
                </div>
                <div className="filter-container">
                    <div className="ml-auto">
                        <input type="text" placeholder="Search..." className="form-control" maxLength="50" ></input>
                    </div>
                </div>
            </div>
            <div className="card-body"> */}
                <ADHomeItems />
            {/* </div>
        </div> */}
        </div>
    )
}
