import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from "@material-ui/core/Icon";
import { MDBDataTableV5 } from 'mdbreact';
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead  } from 'mdbreact';
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import TextField from '@material-ui/core/TextField';


import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';



import Paper from '@material-ui/core/Paper';
import { NavLink as RouterLink } from 'react-router-dom';
import './UserList.css';
import {hostname} from '../../hostConfiguration.js';
import NewUserDialog from '../newUserDialog/newUserDialog.js';
import RoutedUser from '../RoutedUser/RoutedUser';



const useStyles = makeStyles({
    // table: {
    //   minWidth: 700,
    // },
    userlistContainer: {
        padding: '25px',
        border: '1px solid #D8D8D8',
        borderRadius: '3px',
        margin: '25px',
        position: 'relative'

    },
    heading: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
      fontWeight: 400,
      color: "#748A9D"
    },
    addNew: {
        lineHeight: 2,
        fontWeight: 400,
        backgroundColor: '#C54D39',
        color: 'white',
        borderRadius: '25px',
        width: '118px',
        textAlign: 'center',
        height: '29px',
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#EFEFEF !important"
    },
    outerContainer: {
      display: 'grid',
      gridTemplateColumns: '65% 35%',
      // gridGap: '5%'
    },
    userInfo_container: {
      padding: '25px',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      margin: '25px',
      marginLeft: '0px !important'
    },
    hideProfile: {
      display: 'none'
    }
  });
const StyledTableCell = withStyles((theme) => ({
    head: {
      color: '#748A9D',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


function createData(name, active, created, modified, lastLogin) {
    return { name, active, created, modified, lastLogin };
  }
  
  const rows = [
    createData('Abcd', 'Not Active', '14 December 2020 10:00:00', '14 December 2020 12:30:00', '14 December 2020 21:10:00'),
  ];

export default function UserList() {
    const classes = useStyles();
    const [userId, setUserId] = React.useState(1);
    const [usersList, setUserList] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [selectedUserData, setSelectedUserData] = React.useState({});
    const [openDetail, setOpenDetail] = React.useState(false);
    // const [objectProfiles, setObjectProfiles] = React.useState([]);
    const [assignedProfiles, setAssignedProfiles] = React.useState([]);
    const [unAssignedProfiles, setUnAssignedProfiles] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    let selectedData = {username : 'asdf'};
    let objectProfiles =[];
    
    const refresh_token = Cookies.get('refresh_token');
    const access_token = Cookies.get('access_token');
    

    const columns = [
      {
        Header: 'Username',
        accessor: 'username',
        minWidth: 130,
        sortable: true,
        show: true,
        displayValue: "Username",
        sortMethod: (a, b) => {
        console.log(a.props.children,b.props.children);
        if (a.props.children.toLowerCase() > b.props.children.toLowerCase()) {
          return 1
        }
        if (a.props.children.toLowerCase() < b.props.children.toLowerCase()) {
          return -1
        }
      }
      },                                                            
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        sortable: true,
        show: true,
        displayValue: 'Email',
      },
    {
      Header: 'First Name',
      accessor: 'first_name',
      minWidth: 130,
      sortable: true,
      show: true,
      displayValue: 'First Name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      minWidth: 130,
      sortable: true,
      show: true,
      displayValue: 'Last Name',
    },
    {
      Header: 'Department',
      accessor: 'department',
      minWidth: 100,
      sortable: true,
      show: true,
      displayValue: 'Department',
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 80,
      sortable: true,
      show: true,
      displayValue: 'Status',
    },
    {
      Header: 'Last Login',
      accessor: 'last_login',
      minWidth: 150,
      sortable: true,
      show: true,
      displayValue: 'Last Login',
    }
  ]

  const handleSearch = (event) =>{
    let  searchInput  = event.target.value;
    // console.log(userData[0].last_login);
    let filteredData = userData.filter(value => {
      return (
        value.username.props.children.toLowerCase().includes(searchInput.toLowerCase()) ||
        (value.email ? value.email.toLowerCase().includes(searchInput.toLowerCase()) : null) ||
        (value.first_name ? value.first_name.toLowerCase().includes(searchInput.toLowerCase()) : null) ||
        (value.last_name ? value.last_name.toLowerCase().includes(searchInput.toLowerCase()) : null) ||
        (value.department ? value.department.toLowerCase().includes(searchInput.toLowerCase()) : null) ||
        value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
        (value.last_login ? value.last_login.includes(searchInput) : null)
      );
    });
    console.log(filteredData);
    setFilterData(filteredData)
  }

  const refreshToken = () => {
    let refreshTok = {"refreshToken" : refresh_token};

    fetch(hostname.host + '/auth/refresh-token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json'
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      
      if(!a.error) {
        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
      window.location.reload();
      }
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }

    useEffect(() => {
      console.log(access_token);
        fetch(hostname.host + '/admin/users', {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`
          }
         })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
              let dataAll = [];
              a.data.forEach(element => {
                // element.object_profiles = element.object_profiles.map((e) => e.profile_name).join(', ');
                // element['username'] = <span id={element.user_id} onClick={onRowClick} >{element.username}</span>;
                dataAll.push({
                  'username' : <span id={element.user_id} onClick={onRowClick} >{element.username}</span>,
                  'email' : element['email'],
                  'first_name'    : element['first_name'],
                  'last_name'    : element['last_name'],
                  'department'    : element['department'],
                  'status'    : element['status'],
                  'last_login'    : element['last_login'],
                })

              });
                setUserList(a.data);
                let d = {
                  'columns' : columns,
                  'rows' : a.data
                }
                setUserData(dataAll)
              }
            }).catch(error => {
              console.log(error)
            });
        fetch(hostname.host + '/admin/profiles', {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`
         }
         })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
              objectProfiles = a.data.object_profiles;
                // setObjectProfiles(a.data.object_profiles);
                // console.log(a.data);
              }
            });
        
        // fetch(hostname.host + '/refresh_token' , {
        //       method: "POST",
        //       headers: {
        //       'Content-type': 'application/json',
        //       'Authorization': `Bearer ${refresh_token}`,
        //      }
        //      })
    }, []);

    const onRowClick = (event) =>{
      let rowId = event.target.id;
      let assigned = [];
      let unassigned = [];
      setUserId(rowId);
      setOpenDetail(true);

      fetch(hostname.host + '/admin/users/' + rowId, {
        method: "GET",
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${access_token}`
       }
       }) .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
              selectedData = a.data;
              setSelectedUserData(a.data);
              objectProfiles.forEach((e) => {

                if(a.data.object_profiles ? a.data.object_profiles.map((e) => e.profile_name).includes(e.profile_name) : false) {
                  assigned.push(e);
                } else {
                  unassigned.push(e);
                }
              })
              
              setAssignedProfiles(assigned);
              setUnAssignedProfiles(unassigned);
              // selUser['user_id'] = 
              // setProfileId(a.data.profile_id);
              // setProfileName(a.data.profile_name);
              // setProfileType(a.data.profile_type);
              // setProfileCreated(a.data.created_on);
              // setProfileUpdated(a.data.updated_on);
              // a.data.accesses.forEach((ele) => {
              //   let acessObj = ele.access_category;
            
              //   switch (acessObj) {
              //     case 'Region':
              //       regions.push(ele.access_value);
              //       break;
              //     case 'Country':
              //       countries.push(ele.access_value);
              //       break;
              //     case 'Product':
              //       products.push(ele.access_value);
              //       break;
              //     case 'Module':
              //       modules.push(ele.access_value);
              //       break;
              //     case 'Domain':
              //       domains.push(ele.access_value);
              //     break;
                  
              //   }
              // })
              // setProfileRegion(regions);
              // setProfileProduct(products);
              // setProfileCountry(countries);
              // setProfileDomain(domains);
              // setProfileModule(modules);

            }
            });
      

    }

    const closeDetail = () => {
      setOpenDetail(false);
      setSelectedRow(null);
    }
  
    return (
    <div className={classes.container}>
        {/* <a href='/app/admin'><Icon id="backButton">keyboard_backspace</Icon></a>  */}
        <div className={openDetail ? classes.outerContainer : ''}>
        <div className={classes.userlistContainer}>
        <div id="userlistTable">
         <div className={classes.heading}>
           <h4 className={classes.title}>ALL USERS</h4>
           <NewUserDialog  />
         </div>
         {/* <MDBDataTableV5 btn hover 
         data={userData} 
         paging={false}
         searchTop 
         searchBottom={false} /> */}
        <div class="search_filter">
        <TextField
                  variant="outlined"
                  onChange = {handleSearch}
                  id= "profile_search"
                  placeholder= 'search'
                  // value={searchInput}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                />
        </div>
         <ReactTable
          data={filterData.length > 0 ? filterData : userData}
          columns={columns}
          // defaultPageSize={10}
          showPagination= {false}
          minRows = {0}
          className="-highlight"
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  setSelectedRow(rowInfo.index);
                },
                style: {
                  background: rowInfo.index === selectedRow ? 'rgb(251, 244, 242)' : 'white',
                  // color: rowInfo.index === selectedRow ? 'white' : 'black'
                }
              }
            }else{
              return {}
            }
          }}
        />
         </div>
    </div>
    <div className={openDetail ? classes.userInfo_container : classes.hideProfile}>
     
      <RoutedUser userID = {userId} usersData = {selectedUserData} assigned= {assignedProfiles} unassigned = {unAssignedProfiles}
       closeDiv = {closeDetail}/>
    
    </div>
    </div>
    </div>
  );
}