import React, {useState, useEffect} from "react";
import './staticTable.css';

export default function RevenueOverviewTable (props) {
    useEffect(() => {
        console.log('inside static table', props.data);

    })

    const bpsValue =(i) => {
        let value = i;
    if (i < 0) return (<span>{-value} <span class='metricDecrease'>bps decrease</span></span>) 
    else if (i > 0) return (<span>{value} <span class='metricIncrease'> bps increase</span></span>)
    else return <span>{value}</span>
    }

    return (
        <div className="staticGrid">
        {/* {
            <div className="cards">
                <span class='cardLabel'>{props.data[0].label}</span>
                    <div className="cardValue">
                        <span  className="val-unit">$</span>
                        <span>{props.data[0].value}</span>
                    </div>
                    {props.data[0].growth}
            </div>
        } */}
        {
             props.data.map(detail => (
                <div className="cards">
                    <span class='cardLabel'>{detail.label}</span>
                    <div className="cardValue">
                        <span  className="val-unit">{detail.label.split(' ').includes('%') ? '' : '$'}</span>
                        <span class='cardLabel'>{detail.label.split(' ').includes('%') ? detail.value : (detail.value/1000000).toFixed(2)+'M'}</span>
                        <span  className="val-unit">{detail.label.split(' ').includes('%') ? '%' : ''}</span>
                    </div>
                       { bpsValue(detail.growth) }
                </div>
            ))
        }
    </div>    
                        
    )
}