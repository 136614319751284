import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import './newProfileDialog.css';
import {hostname} from '../../hostConfiguration.js';
import Cookies from 'js-cookie';



const useStyles = makeStyles((theme)=> ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 182,
      marginTop: 28,
      display: 'flex',
      justifyContent: 'space-between',
      color: '#748A9D',
      fontFamily: 'Montserrat',
      },
      cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
      addNew: {
        lineHeight: 2,
        fontWeight: 400,
        backgroundColor: '#C54D39',
        color: 'white',
        borderRadius: '25px',
        width: '118px',
        textAlign: 'center',
        height: '29px',
    }
    
}));

export default function NewProfileDialog() {
  const [open, setOpen] = React.useState(false);
  const [profileType, setProfileType] = React.useState('Object');
  const [modules, setModules] = React.useState([]);
  const [domain, setDomain] = React.useState([]);
  const [region,setRegion] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [selectedModule, setSelectedModule] = React.useState([]);
  const [selectedDomain, setSelectedDomain] = React.useState([]);
  const [selectedRegion,setSelectedRegion] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [message, setMessage] = React.useState('Loading ...');
  const form = {};
  const classes = useStyles();

  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');

  const refreshToken = () => {
    let refreshTok = {"refreshToken" : refresh_token};
    fetch(hostname.host + '/auth/refresh-token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json',
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      Cookies.set('access_token', a.accessToken);
      Cookies.set('refresh_token', a.refreshToken);
      window.location.reload();
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }


  useEffect(() => {
    // checkExpiry();
    fetch(hostname.host + '/admin/access_objects', {
      method: "GET",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${access_token}`
     }
     })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            const modules = a.data.filter((e) => e.access_object_type == 'Module');
            const domain = a.data.filter((e) => e.access_object_type == 'Domain');
            setModules(modules);
            setDomain(domain);
          }
        });
    fetch(hostname.host + '/admin/filters', {
      method: "GET",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${access_token}`
     }
     })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            const regions = a.data.filter((e) => e.filter_name == 'Region');
            const countries = a.data.filter((e) => e.filter_name == 'Country');
            const products = a.data.filter((e) => e.filter_name == 'Product');
            setRegion(regions);
            setCountry(countries);
            setProduct(products);
            console.log(regions, countries);
          }
        });


    }, []);

    const handleChange = (event) => {
        // setSelectedDataProfiles(event.target.value);
      };

      const handleRegionChange = (values) => {

        let region_ids = [];
        region.forEach((e) => {
          if (values.includes(e.filter_value)){
            region_ids.push(e.filter_id);
          }
        })
        console.log(region_ids);
  
        let regions = { "parent_filters" : region_ids};
        fetch(hostname.host + "/admin/subfilters" , {
          method: "POST",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`
  
         },
         body: JSON.stringify(regions)
         })
         .then((result) => result.json())
         .then((info) => { 
           if(!info.error) {
             console.log(info);
             setCountry(info.data);
           }
          })
      }
    const handleObjectChange = (event) => {
        const name = event.target.name;
        const values = event.target.value;
        switch(name) {
            case 'module_access':
                setSelectedModule(event.target.value);
                break;
            case 'domain_access':
                setSelectedDomain(event.target.value);
                break;
            case 'country_access':
                setSelectedCountry(event.target.value);
                break;
            case 'region_access':
                // setSelectedRegion(event.target.value);
                setSelectedRegion(event.target.value);
                if(event.target.value.length == 0) {
                  console.log('empty');
                  setSelectedCountry([]);
                }
                handleRegionChange(event.target.value);
                break;
            case 'product_access':
                setSelectedProduct(event.target.value);
                break;

          }
        // setSelectedModule(event.target.value);
    }
    const handleSnackClose =() =>{
        setSnackbarOpen(false);
    }

  const createUser = () => {
    if (formData.profile_name ) {
      setMessage("Loading..."); 
      setSnackbarOpen(true);
      let moduleData = [];
      let domainData = [];
      let productData = [];
      let regionData = [];
      let countryData = [];
      for (let a =0; a < selectedModule.length; a++) {
        modules.forEach((i) =>{ 
          if(i.access_object_name == selectedModule[a]) {
            moduleData.push(i.access_object_id);
          }
        });
      }
      for (let a =0; a < selectedDomain.length; a++) {
        domain.forEach((i) =>{ 
          if(i.access_object_name == selectedDomain[a]) {
            domainData.push(i.access_object_id);
          }
        });
      }
      for (let a =0; a < selectedRegion.length; a++) {
        region.forEach((i) =>{ 
          if(i.filter_value == selectedRegion[a]) {
            regionData.push(i.filter_id);
          }
        });
      }
      for (let a =0; a < selectedCountry.length; a++) {
        country.forEach((i) =>{ 
          if(i.filter_value == selectedCountry[a]) {
            countryData.push(i.filter_id);
          }
        });
      }
      for (let a =0; a < selectedProduct.length; a++) {
        product.forEach((i) =>{ 
          if(i.filter_value == selectedProduct[a]) {
            productData.push(i.filter_id);
          }
        });
      }
      formData['access_objects'] = moduleData.concat(domainData);
      formData['filters'] = regionData.concat(countryData, productData);
    // checkExpiry();
    fetch(hostname.host + '/admin/profiles' , {
     method: "POST",
     headers: {
     'Content-type': 'application/json',
     'Authorization': `Bearer ${access_token}`
    },
    body: JSON.stringify(formData)
    })
    .then((result) => result.json())
    .then((a) => { 
      if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
        console.log(a.error.status);
        refreshToken();
      } else {
        console.log(a.response.alert.message);
        setMessage(a.response.alert.message);
        
        setSnackbarOpen(true); 
        window.location.reload();
      }
    })
    .catch(error => {
      console.log(error);
      setMessage("Unable to add profile"); 
      
      setSnackbarOpen(true); 
    });
    } else {
      setMessage("Please fill all the required fields"); 
      
      setSnackbarOpen(true); 

    }
    
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createAnother = () => {
    document.querySelectorAll("input").forEach(
      input => (input.value = "")
    );
    setSelectedModule([]);
    setSelectedDomain([]);
    setSelectedProduct([]);
    setSelectedRegion([]);
    setSelectedCountry([]);
  }

  const changeField = event => {
    formData[event.target.id] = event.target.value;
    // console.log(formData);
    setFormData(formData);
    }
    const changeType = event => {
        setProfileType(event.target.value);
        if (event.target.value == 'Object') {
            setSelectedProduct([]);
            setSelectedRegion([]);
            setSelectedCountry([]);
        } else if ((event.target.value == 'Data')){
            setSelectedModule([]);
            setSelectedDomain([]);
        }
    }

  return (
    <div >
      <p onClick={handleClickOpen} className={classes.addNew}>
      Create New
      </p>
      <Dialog class="newProfileDialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="newProfileForm">CREATE NEW PROFILE</DialogTitle>
        <DialogContent>
        {/* <Card> */}
    {/* <CardBody> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
          <div>Profile Name*</div>
          <TextField
            // label="Username"
            variant="outlined"
            id="profile_name"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Profile Type*</div>
        <Select
          labelId="Data Profile"
          id="profile_type"
          value={profileType}
          onChange={changeType}
        >
          <MenuItem value= 'Object'>Object Profile</MenuItem>
          <MenuItem value= 'Data'>Data Profile</MenuItem>
        </Select>
        </form>
        </GridItem>
        <div id={(profileType == 'Object') ? 'showObject' : 'hideObject'}>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Module Access</div>
        <Select
          labelId="Module Access"
          id="module_access"
          name="module_access"
          multiple
          value={selectedModule}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {modules.map((i) => (
          <MenuItem key={i.access_object_name} value={i.access_object_name}>
              <Checkbox checked={selectedModule.indexOf(i.access_object_name) > -1} />
              <ListItemText primary={i.access_object_name} />
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Domain Access</div>
        <Select
          labelId="Domain Access"
          id="domain_access"
          name= "domain_access"
          multiple
          value={selectedDomain}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {domain.map((i) => (
          <MenuItem key={i.access_object_name} value={i.access_object_name}>
              <Checkbox checked={selectedDomain.indexOf(i.access_object_name) > -1} />
              <ListItemText primary={i.access_object_name} />
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        </div>
        <div  id={(profileType == 'Data') ? 'showObject' : 'hideObject'}>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Region Access</div>
        <Select
          labelId="Region Access"
          id="region_access"
          name="region_access"
          multiple
          value={selectedRegion}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {region.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedRegion.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Country Access</div>
        <Select
          labelId="Country Access"
          id="country_access"
          name = "country_access"
          multiple
          value={selectedCountry}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {country.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedCountry.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Product Access</div>
        <Select
          labelId="Product Access"
          id="product_access"
          name="product_access"
          multiple
          value={selectedProduct}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {product.map((i) => (
          <MenuItem key={i.filter_value} value={i.filter_value}>
              <Checkbox checked={selectedProduct.indexOf(i.filter_value) > -1} />
              <ListItemText primary={i.filter_value} />
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        </div>
      </GridContainer>
    {/* </CardBody> */}
    {/* <CardFooter>
      <Button id="btn_login" >Create User</Button>
    </CardFooter> */}
  {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" id="sideButton">
            Cancel
          </Button>
          <Button onClick={createAnother} color="primary" id="sideButton">
            Create Another
          </Button>
          <Button onClick={createUser} color="primary" id="createNew">
            Create and Exit
          </Button>
        </DialogActions>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </Dialog>
    </div>
  );
}