import React, {useState, useEffect} from "react";
import { Outlet } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import props from 'prop-types';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './RoutedUser.css';
import Icon from "@material-ui/core/Icon";
import {hostname} from '../../hostConfiguration.js';
import PropTypes from "prop-types";
import AssignProfiles from '../AssignProfiles/AssignProfiles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';

import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TransferList from '../../components/TransferList/TransferList';
import Cookies from 'js-cookie';





const useStyles = makeStyles({
    container: {
        // padding: "13%"
    },
    root: {
      margin: '7px 0px 25px',
      display: 'grid',
      gridTemplateColumns: '42% 14% 42%'
    },
    heading: {
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between"
    },
    title: {
      fontWeight: 400,
    },
    userInfo: {
        // padding: "5%",
        marginTop: "3%",
        backgroundColor: "white",
        position: "relative"
    },
    row: {
        padding: "1%",
        marginBottom: "10px",
        display: 'grid',
        gridTemplateColumns: '45% 55%'
    },
    label: {
        fontWeight: 600,
        fontSize: '12px',
    },
    buttons: {
        float: "right",
        marginTop: "2%"
    },
    button: {
        margin: 10,
    },
    value: {
        // position: "absolute",
        // left: "50%",
    },
    buttonsRow: {
        display: 'flex',
        float: 'right',
    },
    input_fields : {
        display: 'none'
    },
    inputs_position : {
        // position: "absolute",
        //   left: "45%",
    },
    enableEdit: {
        display: 'none',
      },
      buttonsRow: {
        display: 'flex',
        float: 'right',
      },
      editButtons: {
        display: 'none'
      },
      paper: {
        // width: 149,
        height: 230,
        overflow: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        boxShadow: 'none'
      },
      button: {
        // margin: theme.spacing(0.5, 0),
        minWidth: '31px'
      },

  });

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

const RoutedUser = ({
    text,
    type,
    placeholder,
    children,
    ...props
  }) => {
    const classes = useStyles();
    const [isEditing, setEditing] = useState(false);
    const handleKeyDown = (event, type) => {
    };
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [user, setUser] = React.useState(false);
    const [enableEdit, setEnableEdit] = React.useState(false);
    const [dataProfiles, setDataProfiles] = React.useState([]);
    const [object_profiles, setObjectProfiles] = React.useState([]);
    const [selectedDataProfiles, setSelectedDataProfiles] = React.useState('');
    const [selectedObjectProfiles, setSelectedObjectProfiles] = React.useState([]);
    const [message, setMessage] = React.useState('Loading ...');

    const [username, setUsername] = React.useState(props.usersData.username);
    const [displayname, setDisplayname] = React.useState(props.usersData.display_name);
    const [firstname, setFirstname] = React.useState(props.usersData.first_name);
    const [lastname, setLastname] = React.useState(props.usersData.last_name);
    const [email, setEmail] = React.useState(props.usersData.email);
    const [title, setTitle] = React.useState(props.usersData.title);
    const [department, setDepartment] = React.useState(props.usersData.department);

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [formData, setFormData] = React.useState({});

    const refresh_token = Cookies.get('refresh_token');
    const access_token = Cookies.get('access_token');
  
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
  
    const handleToggle = (value) => () => {
      console.log(document.getElementById("transferlistItem"+ value).style.backgroundColor);
      if (document.getElementById("transferlistItem"+ value).style.backgroundColor == "rgb(251, 244, 242)"){
        console.log('fghjk');
        document.getElementById("transferlistItem"+ value).style.backgroundColor = "";
      }else document.getElementById("transferlistItem"+ value).style.backgroundColor = "#fbf4f2";
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleAllRight = () => {
      setRight(right.concat(left));
      setLeft([]);
    };
  
    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
    };

    const refreshToken = () => {
      let refreshTok = {"refreshToken" : refresh_token};

      fetch(hostname.host + '/auth/refresh-token' , {
        method: "POST",
        headers: {
        'Content-type': 'application/json',
        },
        body: JSON.stringify(refreshTok)
      }).then(response => response.json())
      .then(a => {
        console.log(a.data);
        
      if(!a.error) {
        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
        window.location.reload();
      }
      })
  
    }
  
    // const checkExpiry = () => {
    //   let dateNow = new Date();
    //   if (access_expiry < dateNow.getTime()) {
    //     refreshToken();
    //   }
    // }

    useEffect(() => {
        setEnableEdit(false);
        const url = hostname.host + "/admin/users/"+ props.userID;
        fetch(url, {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
          }
         })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
                setUser(a.data);
              }
            });

        fetch(hostname.host + '/admin/profiles', {
          method: "GET",
          headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
         }
         })
            .then(response => response.json())
            .then(a => {
              if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                console.log(a.error.status);
                refreshToken();
              } else {
                setDataProfiles(a.data.data_profiles);
                setObjectProfiles(a.data.object_profiles);
              }
            });

        // fetch(hostname.host + '/refresh_token' , {
        //       method: "POST",
        //       headers: {
        //       'Content-type': 'application/json',
        //       'Authorization': `Bearer ${refresh_token}`,
        //      }
        //      })
        
    }, [props.userID]);

     const editUser = () => {
      let longest = props.assigned.concat(props.unassigned).map((e)=> e.profile_name).reduce(
        function (a, b) {
            return a.length > b.length ? a : b;
        }
      );
      let divs = document.getElementsByClassName('UsersTransferList');
      for (var i = 0; i < divs.length; i++) {
        divs[i].style.width=((longest.length)+'ch');
      }
        setEnableEdit(true);
        console.log(props.assigned.map((e) => e.profile_name));
        setLeft(props.unassigned.map((e) => e.profile_name));
        setRight(props.assigned.map((e) => e.profile_name));

        setUsername(props.usersData.username);
        setDisplayname(props.usersData.display_name);
        setFirstname(props.usersData.first_name);
        setLastname(props.usersData.last_name);
        setEmail(props.usersData.email);
        setDepartment(props.usersData.department);
        setTitle(props.usersData.title);
        setSelectedDataProfiles(props.usersData.data_profile ? props.usersData.data_profile.profile_name : '');
      }
    const changeField =(event) => {
        // formData[event.target.id] = event.target.value;
        let selectedVal = event.target.value;
        setFormData(formData);
        switch(event.target.id) {
          case 'username':
              setUsername(selectedVal);
              break;
          case 'display_name':
              setDisplayname(selectedVal);
              break;
          case 'first_name':
              setFirstname(selectedVal);
              break;
          case 'last_name':
              setLastname(selectedVal);
              break;
          case 'email':
              setEmail(selectedVal);
              break;
          case 'department':
              setDepartment(selectedVal);
              break;
          case 'title':
              setTitle(selectedVal);
              break;
      }
    }

    const changeType = (event) => {
        console.log(event.target.name, event.target.value);
            setSelectedDataProfiles(event.target.value);
    }

    const closeDetailView = () => {
      props.closeDiv();
      setEnableEdit(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    const saveChanges =() => {
      
      let dataPro = dataProfiles.filter((e) => e.profile_name == selectedDataProfiles)[0];
      let assignedIds = [];
      let unassignedIds = [];
      object_profiles.forEach((i) => {
        if (right.includes(i.profile_name)) {
          assignedIds.push(i.profile_id)
        }
      })
      formData['data_profile'] = dataPro ? dataPro.profile_id : null;
      formData['object_profiles'] = assignedIds;
      formData['username'] = username;
      formData['display_name'] = displayname;
      formData['first_name'] = firstname;
      formData['last_name'] = lastname;
      formData['email'] = email;
      formData['department'] = department;
      formData['title'] = title;
      
      setEnableEdit(false);
      setDialogOpen(false);
      fetch(hostname.host + '/admin/users/' + props.userID , {
        method: "PUT",
        headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
       },
       body: JSON.stringify(formData)
       })
       .then((result) => result.json())
       .then((a) => { 
        if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
          console.log(a.error.status);
          refreshToken();
        } else {
           setMessage(a.response.alert.message);
           setDialogOpen(false);
           setOpen(true);
           window.location.reload();
        }
       })
       .catch(error => {
         console.log(error);
         setMessage("Unable to Edit user"); 
         
         setOpen(true); 
       });
      //  fetch(hostname.host + '/refresh_token' , {
      //   method: "POST",
      //   headers: {
      //   'Content-type': 'application/json',
      //   'Authorization': `Bearer ${refresh_token}`,
      //  }
      //  })
    };
    const saveChangesDialog = () => {
        setDialogOpen(true);
    };
    const cancelDialog = () => {
        setDialogOpen(false);
    };
    const deactivateUser = () => {
        console.log(props.usersData.status);
        fetch(hostname.host + "/admin/users/" + props.usersData.user_id , {
            method: "PATCH",
            headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${access_token}`
           }
           })
           .then((result) => result.json())
           .then((a) => { 
            if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
              console.log(a.error.status);
              refreshToken();
            } else {
              if(props.usersData.status == 'inactive') {
                setMessage('Activated User Succesfully');
              } else {
                setMessage('Deactivated User Succesfully');
              }
              setOpen(true);
              window.location.reload();
            }
           })
           .catch(error => {
            console.log(error);
            setMessage("Unable to Deactivate User"); 
            
            setOpen(true); 
          });
    }

    const customList = (items) => (
      <Paper className={classes.paper}>
        <List dense component="div" role="list" class="UsersTransferList">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
  
            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)} id={"transferlistItem"+ value}>
                {/* <ListItemIcon class='checkboxIcon'>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    class="checkboxImg"
                  />
                </ListItemIcon> */}
                <ListItemText id={labelId} primary={value} class='transferListLabel' />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );

    const deleteUser = () =>{
        console.log(props.usersData.user_id);
        
        fetch(hostname.host + "/admin/users/" + props.usersData.user_id , {
            method: "DELETE",
            headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
           }
           })
           .then((result) => result.json())
           .then((a) => { 
            if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
              console.log(a.error.status);
              refreshToken();
            } else {
              setMessage('Deleted User Succesfully');
              setOpen(true);
              window.location.reload();
            }
           })
           .catch(error => {
            console.log(error);
            setMessage("Unable to Delete User"); 
            
            setOpen(true); 
          });
          };
        
    return (
        <section {...props}>
       <div className={classes.heading}>
    <h4 className={classes.title}>USER DETAILS</h4>
    <CloseIcon onClick={closeDetailView}/>
     </div>
          <div>

              <div className={classes.container}>
            <div className={classes.userInfo}>
            <div className={classes.row}>
                <label className={classes.label}>Username<span className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.username}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  id= "username"
                  value={username}
                  // required
                  disabled
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Display Name<span className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.display_name}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  id= "display_name"
                  value={displayname}
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>First Name</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.first_name}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  id= "first_name"
                  value={firstname}
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Last Name</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.last_name}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  id= "last_name"
                  value={lastname}
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Email<span  className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.email}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  value={email}
                id= "email"
                disabled
                  // required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Department<span  className={enableEdit ? '' : classes.enableEdit}>*</span></label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.department}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  value={department}
                id= "department"
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Title</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.title}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="ediProfileInput">
                <TextField
                  variant="outlined"
                  onChange = {changeField}
                  value={title}
                id= "title"
                  required
                />
                </span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Data Profile</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{props.usersData.data_profile ? props.usersData.data_profile.profile_name : ''}</span>
                <span className={enableEdit ? classes.inputs_position : classes.input_fields} id="editDataProfile">
                <Select
                name="data_profile"
                value={selectedDataProfiles}
                onChange={changeType}
                >
                {dataProfiles.map((i) => (
                <MenuItem key={i.profile_name} value={i.profile_name}>
                {/* <Checkbox checked={selectedDataProfiles.indexOf(i.access_object_name) > -1} />
                <ListItemText primary={i.access_object_name} /> */}
                {i.profile_name}
                </MenuItem>
                   ))} 
                </Select>
                </span>
            </div>
            <div className={enableEdit ? '' : classes.row}>
                <label className={classes.label}>Object Profile</label>
                <span  className={enableEdit ? classes.enableEdit : classes.value}>{ props.usersData.object_profiles ? [...new Set(props.usersData.object_profiles.map((e) => e.profile_name))].join(', ') : ''}</span>
                <span className={enableEdit ? '' : classes.input_fields} id="ediProfileInput">
                    {/* <TransferList  assignedData = {props.assigned} unassignedData = {props.unassigned}/> */}
      <Grid container spacing={2}  alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
                </span>
            </div>
            <div className={classes.row}  id="SystemCapturedInfo">
                <label className={classes.label}>Last Login</label>
                <span  className={classes.value}>{props.usersData.last_login}</span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Created On</label>
                <span  className={classes.value}>{props.usersData.created_on}</span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Updated On</label>
                <span  className={classes.value}>{props.usersData.updated_on}</span>
            </div>
            <div className={classes.row}>
                <label className={classes.label}>Status</label>
                <span  className={classes.value}>{props.usersData.status}</span>
            </div>
            </div>
            </div>
          </div>
          <div className={enableEdit ? classes.editButtons :classes.buttonsRow}>
          <Button  color="primary" id="sideButton"  onClick={deleteUser}>
            Delete User
          </Button>
          <Button  color="primary" id="sideButton"  onClick={deactivateUser}>
             {props.usersData.status == 'inactive' ? 'Activate' : 'Deactivate'}
          </Button>
          <Button  color="primary" id="createNew" onClick={editUser}>
            Edit
          </Button>
          </div>
          <div className={enableEdit ? classes.buttonsRow :classes.editButtons}>
          <Button variant="outlined" color="primary" id="createNew" onClick={saveChangesDialog}>
          Save Changes
        </Button>
          <Dialog aria-labelledby="customized-dialog-title" open={dialogOpen}>
        <DialogContent dividers>
          <Typography gutterBottom>
          ARE YOU SURE YOU WANT TO SAVE THE CHANGES MADE?
          </Typography>
         
        </DialogContent>
        <DialogActions>
        <Button  onClick={cancelDialog} >
            Cancel
          </Button>
          <Button autoFocus onClick={saveChanges} id="createNew" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </section>
    );
  };

export default RoutedUser;

RoutedUser.propTypes = {
    userID: PropTypes.number
    
  };
  
