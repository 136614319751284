import { makeStyles } from '@material-ui/core/styles';
import React,  {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Admin from "app.js";
import './index.css';
import loader from "assets/img/loader.svg";

import Login from "views/login/login.js";


import RequestAccess from "views/RequestAccess/RequestAccess";
import Cookies from 'js-cookie';
import {hostname} from './hostConfiguration.js';

const useStyles = makeStyles((theme) => ({
    hideLayout: {
        display: 'none'
      },
    showLayout : {
        display: 'block'
    },
    loader : {
        display: 'flex',
        justifyContent: 'center',
        padding: '15% 0%'
    },
    errorMessage : {
        display: 'grid',
        justifyContent: 'center',
        padding: '15% 0%'
    }
}));

export default function Main() {
    const classes = useStyles();
    const [cookie, setCookie] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);


const username = Cookies.get('username');
    const uname = { "username" : username };
    fetch(hostname.host + '/auth/login', {
        method: "POST",
        headers: {
        'Content-type': 'application/json',
       },
      body: JSON.stringify(uname)
       })
          .then(response => response.json())
          .then(a => {
            if(!a.error) {
                Cookies.set('access_token', a.accessToken);
                Cookies.set('refresh_token', a.refreshToken);
            // window.location.href='/app/home'
            setCookie(true);
            // console.log('loggedIN', Cookies.get('access_token'));
            } else if (a.error && a.error.message == "A user with this username could not be found.") {
                setRedirect(true);
            }
            // else if (a.error && a.error.message == "The user account has been suspended.") {
                
            // }
  
          });
    
        //   console.log('inside main.js file', Cookies.get('access_token'));
return (

    <div>
        {cookie ? 
            <Admin />
          : <div>
              {
                  redirect ?
                //   <div className={classes.errorMessage}>
                //       <h4>Sorry You don't have access to visit the portal. Please Click the below Link to Request for access</h4>
                //       <h4><a href='/userLogin'>Login</a></h4>
                //   </div>
                <Redirect  to="/userLogin?noAccess" />
                 :
                  <div className={classes.loader}>
                   <img src={loader}></img>
                 </div>

              }

            </div>
        }
    </div>
        //  <div  className = {(cookie == true )? classes.showLayout : classes.hideLayout }><Admin /></div>

    
)


}
