import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {hostname} from '../../hostConfiguration.js';

import './setAnnouncements.css';



const useStyles = makeStyles((theme)=> ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 182,
        marginTop: 28
      }
    
}));

export default function SetAnnouncements() {
    const classes = useStyles();
    const [announcement, setAnnouncement] = useState('');
    const [open, setOpen] = React.useState(false);
    const [selectedType, setSelectdType] = useState([]);
    const [message, setMessage] = React.useState('');
    // let formData = {};

    useEffect(() => {

        // const url = "http://localhost:5000/admin/profiles/";
        // fetch(url)
        //     .then(response => response.json())
        //     .then(a => {
        //         const getTypes = a.data;
        //         setTypes(getTypes);
        //     });
    }, []);    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const handleAnnouncement = (event) => {
         setAnnouncement(event.target.value);
        // console.log(formData);

    }

    const handleTypeChange = (event) => {
        setSelectdType(event.target.value);
        // formData['announcement_type'] = event.target.value;
    }

    const createAnnouncement = () => {
      let formData = {};
      formData['announcement'] = announcement;
      formData['announcement_type'] = selectedType;
        // formData['announcement_type'] = selectedType;
      console.log(formData);
        fetch(hostname.host + '/admin/announcements' , {
          method: "POST",
          headers: {
          'Content-type': 'application/json'
         },
         body: JSON.stringify(formData)
         })
         .then((result) => result.json())
         .then((info) => { 
             console.log(info.response.alert.message);
             setMessage(info.response.alert.message);
             
             setOpen(true); 
         })
         .catch(error => {
           console.log(error);
           setMessage("Unable to add user"); 
           
           setOpen(true); 
         });
        // setMessage(info.response.alert.message);
        // setOpen(true);

    }

    return(<div>
    <Card>
        <CardBody>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
        <form className={classes.formControl} id='AnnouncementText'>
          <TextField
            label="Announcement"
            id="standard-basic"
            // variant="outlined"
            onChange = {handleAnnouncement}
            required
          />  
          </form>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          {/* <form className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="announcement_type"
            //   class = "user_gender"
            //   value={selectedType}
              onChange={handleTypeChange}
            >
            {types.map((name) => (
            <MenuItem value={name}>name</MenuItem>
             ))} 
            </Select>
          </form> */}
           <form variant="outlined" className={classes.formControl}>
        <div>Announcement Type</div>
        <Select
          labelId="Announcement_Type"
          // id="profile_type"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <MenuItem value= 'Event'>Event</MenuItem>
          <MenuItem value= 'Updates'>Updates</MenuItem>
          <MenuItem value= 'Notice'>Notice</MenuItem>
        </Select>
        </form>
          </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
        <Button id="btn_login"  onClick={createAnnouncement}>Submit Announcement</Button>
        </CardFooter>
    </Card>
    <Snackbar
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>)
}