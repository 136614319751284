import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import './termsOfUse.css';


export default function Terms(props) {
  const [openDialog, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState('paper');


  const handleClose = () => {
    setOpen(false);
    props.closeButton(openDialog);
  };

//   const handleDialog = () => {
//     setOpen(props.open)
//     console.log(openDialog);
//   };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    setOpen(true)
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  return (
    <div>
      {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button> */}
      {/* {handleDialog} */}
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Use</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
           <h4>1. Acceptance of Terms</h4> 
            Please read and accept the Equinix Terms of Use ("Terms") before using the www.equinix.com Web site or any other Web site maintained by Equinix ("Web site"). If you do not agree to all of the Terms, do not use this Web site. Equinix may update these Terms from time to time. Each time you use the Web site you will be operating under the Terms as they exist as of the time of use. Please check these terms prior to each use of our Web site.
            The Terms are entered into by and between Equinix and you. If you currently have in effect a separate written purchase or license agreement with Equinix for a service, that agreement shall govern the use of that service to the extent it conflicts with these Terms.
           <h4>2. Disclaimer</h4>    
            Although Equinix has attempted to provide accurate information on the Web site, Equinix assumes no responsibility for the accuracy of the information. Equinix may change the programs or products mentioned at any time without notice. Mention of non- Equinix products or services is for information purposes only and constitutes neither an endorsement nor a recommendation.
            THE MATERIALS ARE PROVIDED "AS IS" WITHOUT AN EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY, OR FITNESS FOR ANY PARTICULAR PURPOSE.
            IN NO EVENT SHALL EQUINIX OR ITS SUPPLIERS OR PARTNERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE USE OF OR INABILITY TO USE THE MATERIALS, EVEN IF EQUINIX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            ELECTRONIC COMMUNICATIONS PRIVACY ACT NOTICE (18USC 2701-2711): COMPANY MAKES NO GUARANTY OF CONFIDENTIALITY OR PRIVACY OF ANY COMMUNICATION OR INFORMATION TRANSMITTED ON THE SITE OR ANY WEB-SITE LINKED TO THE WEBSITE. Company will not be liable for the privacy of e-mail addresses, registration and identification information, disk space, communications, confidential or trade-secret information, or any other Content stored on Company’s equipment, transmitted over networks accessed by the Site, or otherwise connected with Subscriber’s use of the Services.
           <h4>3. Intellectual Property Rights</h4>
            All content, including text, data, sound recordings, sound, photographs, graphics, video, or other materials ("Material") provided on this Web site is provided by or to Equinix by its respective manufacturers, authors, developers and vendors (the "Third Party Providers") and is the copyrighted work of Equinix and/or the Third Party Providers.
            Except as expressly authorized by Equinix or the Third Party Provider, none of the Material may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise. No part of the Web site, including logos, graphics, sounds or images, may be reproduced or retransmitted in any way, or by any means, without the prior express written permission of Equinix. You also may not, without Equinix’s prior express written permission, copy any Materials contained on this Web site on any other server, or use any Equinix Marks for any purpose.
            "Equinix Marks" refers to any names, marks, brands, logos, designs, slogans, trademarks, services marks and other designations Equinix uses in connection with its products or services. Individuals who believe that their intellectual property rights have been infringed on the Web site, may contact Equinix and request that the infringing material be removed or access to it blocked. Equinix will investigate those complaints. Where Equinix is satisfied that the infringement is taking place, it will take appropriate action. If you believe your intellectual property rights are being infringed, please direct your claims to: General Counsel, Equinix, Inc. 1 Lagoon Dr., Redwood City, CA 940654.
           <h4>4. Trademarks</h4>
            The trademarks, logos and service marks ("Marks") displayed on this Web site are the property of Equinix or other third parties. Users are not permitted to use these Marks without the prior written consent of Equinix or such third party which may own the Mark.
            Equinix and IBX are registered trademarks of Equinix, Inc. International Business Exchange is a trademark of Equinix, Inc.
           <h4>5. Links to Third Party Sites</h4>
            This Web site may contain links to third party sites. Access to any other Internet site linked to this Web site is at the user's own risk and Equinix is not responsible for the accuracy or reliability of any information, data, opinions, advice or statements made on these sites. Equinix provides these links merely as a convenience and the inclusion of such links does not imply an endorsement.
           <h4>6. Access to Password-Protected and/or Secure Areas</h4>
            Access to and use of password protected and/or secure areas of the Web site is restricted to authorized users only. Unauthorized individuals attempting to access these areas of the Web site may be subject to prosecution.
           <h4>7. Privacy Policy</h4>
            Equinix respects your desire for privacy. Equinix’s Privacy Policy can be found at www.equinix.com/privacy/. By using the Web site, you are consenting to the processing of your data by Equinix and consenting to the terms of our Privacy Policy.
            Use of the Web site or Services to violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material including that are deemed threatening or obscene, or engage in any kind of illegal activity is expressly prohibited. You will not run Maillist, Listserv, any form of auto-responder, or "spam" on the Site, or any processes that run or are activated while the Subscriber is not logged in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          <p onClick={handleClose} class='cancelTerms'>
            Cancel
          </p>
        </DialogActions>
      </Dialog>
    </div>
  );
}
Terms.propTypes = {
    open: PropTypes.bool,
    closeButton: PropTypes.func
  };