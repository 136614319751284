import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function DonutChart() {
    useLayoutEffect(() => {
    // Create chart instance
var chart = am4core.create("singleDonutChart", am4charts.PieChart);

// Add data
// chart.data = [
//     {
//         "name": "AMER",
//         "usd": 12312,
//         "growth": "33.33%",
//         "color": am4core.color("#CD503C")
//     },
//     {
//         "name": "APAC",
//         "usd": 12312,
//         "growth": "33.33%",
//         "color": am4core.color("#DC7431")
//     },
//     {
//         "name": "EMEA",
//         "usd": 12312,
//         "growth": "33.33%",
//         "color": am4core.color("#F0CC71")
//     }
// ]
const dummydata = [{  
    id: 1,      
    "totleMRR": "1234923",
"lable":"Customer MRR",
"data": [
        {
            "name": "AMER",
            "usd": 12312,
            "growth": "33.33%",
            "color": am4core.color("#CD503C")
        },
        {
            "name": "APAC",
            "usd": 12312,
            "growth": "33.33%",
            "color": am4core.color("#DC7431")
        },
        {
            "name": "EMEA",
            "usd": 12312,
            "growth": "33.33%",
            "color": am4core.color("#F0CC71")
        }
]
}];

console.log(dummydata[0].data);
chart.data = dummydata[0].data;

// var title = chart.titles.create();
// title.text = dummydata[0].lable;
// title.fontSize = 25;
// title.marginBottom = 30;
// Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "usd";
pieSeries.dataFields.category = "name";
pieSeries.dataFields.text = dummydata[0].lable
// pieSeries.labels.template.disabled = false;
pieSeries.ticks.template.disabled = true;
pieSeries.slices.template.propertyFields.fill = "color";
pieSeries.labels.template.text = "{name} \n {growth}";
pieSeries.labels.template.radius = am4core.percent(-10);
pieSeries.labels.template.fontSize = "10";
pieSeries.labels.template.fontFamily="Montserrat"
// pieSeries.labels.template.color = "#fff";
// pieSeries.tooltip.getFillFromObject = false;
// pieSeries.tooltip.background.fill = am4core.color("color");
// pieSeries.tooltip.label.fill = am4core.color("#fff");

// chart.legend = new am4charts.Legend();
// chart.legend.position = "bottom";

chart.innerRadius = am4core.percent(65);

// var label1 = pieSeries.createChild(am4core.Label);
// label1.text = dummydata[0].lable;
// label1.horizontalCenter = "middle";
// label1.verticalCenter = "bottom";
// label1.fontSize = 20;
// label1.marginBottom = 30;

var label = pieSeries.createChild(am4core.Label);
if(dummydata[0].id === 1){
    label.text =  " Customer MRR \n [bold] ${values.value.sum}";
}
label.horizontalCenter = "middle";
label.verticalCenter = "middle";
label.fontSize = 15;
label.fontFamily = "Montserrat"



}, []);
    return (
        <div>
            <div id="singleDonutChart" style={{ width: "100%", height: "300px" }}></div>
        </div>
    )
}

export default DonutChart

















// import React, { useRef, useLayoutEffect } from 'react';
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// function SingleDonut() {
//     useLayoutEffect(() => {
//     // Create chart instance
// var chart = am4core.create("chartdiv", am4charts.PieChart);

// // Add data
// chart.data = [{
//   "country": "Lithuania",
//   "value": 501.9
// }, {
//   "country": "Czechia",
//   "value": 301.9
// }, {
//   "country": "Ireland",
//   "value": 201.1
// }, {
//   "country": "Germany",
//   "value": 165.8
// }, {
//   "country": "Australia",
//   "value": 139.9
// }, {
//   "country": "Austria",
//   "value": 128.3
// }];

// // Add and configure Series
// var pieSeries = chart.series.push(new am4charts.PieSeries());
// pieSeries.dataFields.value = "value";
// pieSeries.dataFields.category = "country";
// pieSeries.labels.template.disabled = true;
// pieSeries.ticks.template.disabled = true;

// chart.legend = new am4charts.Legend();
// chart.legend.position = "right";

// chart.innerRadius = am4core.percent(60);

// var label = pieSeries.createChild(am4core.Label);
// label.text = "${values.value.sum}";
// label.horizontalCenter = "middle";
// label.verticalCenter = "middle";
// label.fontSize = 40;
// }, []);
//     return (
//         <div>
//             <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
//         </div>
//     )
// }

// export default SingleDonut
