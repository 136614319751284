import React, { useEffect, useState } from 'react';
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import FormLabel from '@material-ui/core/FormLabel';
import InputBase from "@material-ui/core/InputBase";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { hostname } from 'hostConfiguration';
import './filters.css';

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",
        fontSize: 12,
        width: "150px",
        padding: "7px 7px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            // boxShadow: `${fade(theme.palette.danger, 0.25)} 0 0 0 0.2rem`,
            borderColor: "orange",
            boxShadow: "0 0 7px rgb(230, 143, 112)",
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f6f6f6',
            borderColor: '#dbd9d9c9',
        },
    },
    applybutton: {
        textTransform: 'none',
        backgroundColor: '#cd503c',
        '&:hover': {
            backgroundColor: '#b62914',
        },
    },
    filters_label: {
        fontFamily: 'Montserrat-Bold',
        fontSize: '12px',
        color: '#757678'

    },
    filters_page: {
        display: 'row',
        gridTemplateRows: '10% 10%',
        gridGap: '3%',
    },
    filters_section: {
        display: 'flex',
        gridTemplateColumns: '30% 30% 30%',
        // gridGap: '5%',
        margin: '20px',
    },
    button: {
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f6f6f6',
            borderColor: '#dbd9d9c9',
        },
    },

    radio: {
        height: '250px',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '150px',
    },
    flex_section: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0',
    },

    flex_col_scroll: {
        flexGrow: '1',
        overflow: 'auto',
        minHeight: '100%',
    },
    checkboxRadioStyle: {
        width: '200px',
        height: '200px',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: '20px'
    },
    checkboxRadioLabel: {
        fontSize: "12px",
        fontFamily: "Montserrat-Bold"
    },

}));

function DialogsRev(props) {
    const [open, setOpen] = React.useState(false);
    //checkboxes
    const [data, setData] = useState([]);
    const [hqRegion, setHqRegion] = useState([]);
    const [hqRegionData, setHqRegionData] = useState({});

    const [hqCountry, setHqCountry] = useState([]);
    const [hqCountryData, setHqCountryData] = useState([]);
    const [hqSearchTerm, setHqSearchTerm] = React.useState("");

    const [deployRegion, setDeployRegion] = useState([]);
    const [deployRegionData, setDeployRegionData] = useState([]);

    const [compSize, setCompSize] = useState([]);
    const [compSizeData, setCompSizeData] = useState([]);

    const [deployCountry, setDeployCountry] = useState([]);
    const [deployCountryData, setDeployCountryData] = useState([]);
    const [deploySearchTerm, setDeploySearchTerm] = React.useState("");

    const [deployMetro, setDeployMetro] = useState([]);
    const [deployMetroData, setDeployMetroData] = useState([]);
    const [deployMetroSearchTerm, setDeployMetroSearchTerm] = React.useState("");

    const [entityType, setEntityType] = useState([]);
    const [entityTypeData, setEntityTypeData] = useState([]);
    const [entityTypeSearchTerm, setEntityTypeSearchTerm] = React.useState("");

    const [salesPrgrm, setSalesPrgrm] = useState([]);
    const [salesPrgrmData, setSalesPrgrmData] = useState([]);

    const [productType, setProductType] = useState([]);
    const [productTypeData, setProductTypeData] = useState([]);

    const [revenueType, setRevenueType] = useState([]);
    const [revenueTypeData, setRevenueTypeData] = useState([]);

    //Radio Buttons
    const [hyperFlag, setHyperFlag] = useState([]);
    //const [hyperval, setHyperVal] = useState('');
    const [hyperFlagData, setHyperFlagData] = useState([]);

    const [lighthouseFlag, setLightHouseFlag] = useState([]);
    //const [lighthouseval, setLightHouseVal] = useState('');
    const [lighthouseFlagData, setLightHouseFlagData] = useState([]);

    const [superMetro, setSuperMetro] = useState([]);
    const [superMetroData, setSuperMetroData] = useState([]);


    const refresh_token = Cookies.get('refresh_token');
    const refreshToken = () => {
        console.log('inside refresh');
        let refreshTok = { "refreshToken": refresh_token };
        fetch(hostname.host + '/auth/refresh-token', {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(refreshTok)
        }).then(response => response.json())
            .then(a => {
                console.log(a);
                if (!a.error) {
                    Cookies.set('access_token', a.accessToken);
                    Cookies.set('refresh_token', a.refreshToken);
                    window.location.reload();
                }
            })
    }

    const fetchAllFiltersData = async () => {
        // const response = await axios.get('https://orbis-mock-data.firebaseio.com/revenue/overview.json?print=pretty');
        const tab = { tabId: 37 }
        fetch(hostname.host + '/module/tab-filters', {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            },
            body: JSON.stringify(tab)
        })
            .then(response => response.json())
            .then(a => {
                if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
                    console.log(a.error.status);
                    refreshToken();
                } else {
                    console.log("RESPONSE DATA:", a.data);
                    setData(a.data);
                    setHqRegionData(a.data.filter((e) => e.filter_id == 7)[0]);
                    setHqRegion(a.data.filter((e) => e.filter_id == 7)[0].filterValues)

                    setHqCountryData(a.data.filter((e) => e.filter_id == 8)[0]);
                    setHqCountry(a.data.filter((e) => e.filter_id == 8)[0].filterValues);

                    setCompSizeData(a.data.filter((e) => e.filter_id == 9)[0]);
                    setCompSize(a.data.filter((e) => e.filter_id == 9)[0].filterValues);

                    setHyperFlagData(a.data.filter((e) => e.filter_id == 10)[0])
                    setHyperFlag(a.data.filter((e) => e.filter_id == 10)[0].filterValues);

                    setLightHouseFlagData(a.data.filter((e) => e.filter_id == 11)[0])
                    setLightHouseFlag(a.data.filter((e) => e.filter_id == 11)[0].filterValues);

                    setDeployRegionData(a.data.filter((e) => e.filter_id == 1)[0]);
                    setDeployRegion(a.data.filter((e) => e.filter_id == 1)[0].filterValues);

                    setDeployCountryData(a.data.filter((e) => e.filter_id == 13)[0]);
                    setDeployCountry(a.data.filter((e) => e.filter_id == 13)[0].filterValues);

                    setDeployMetroData(a.data.filter((e) => e.filter_id == 14)[0]);
                    setDeployMetro(a.data.filter((e) => e.filter_id == 14)[0].filterValues);

                    setEntityTypeData(a.data.filter((e) => e.filter_id == 15)[0]);
                    setEntityType(a.data.filter((e) => e.filter_id == 15)[0].filterValues);

                    setSalesPrgrmData(a.data.filter((e) => e.filter_id == 16)[0]);
                    setSalesPrgrm(a.data.filter((e) => e.filter_id == 16)[0].filterValues);

                    setSuperMetroData(a.data.filter((e) => e.filter_id == 17)[0]);
                    setSuperMetro(a.data.filter((e) => e.filter_id == 17)[0].filterValues);

                    setProductTypeData(a.data.filter((e) => e.filter_id == 2)[0]);
                    setProductType(a.data.filter((e) => e.filter_id == 2)[0].filterValues);

                    setRevenueTypeData(a.data.filter((e) => e.filter_id == 3)[0]);
                    setRevenueType(a.data.filter((e) => e.filter_id == 3)[0].filterValues);

                }
            }).catch(error => {
                console.log(error);
            });

    }
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            fetchAllFiltersData();
        }
        //document.getElementById('breadcrumbs_param').innerHTML = '  >  REVENUE  >  OVERVIEW';

        return () => {
            unmounted = true
        }
    }, [])
    // useEffect(()=> {
    //     console.log("GQ REGION:",hqRegion);
    // },[hqRegion])

    // const { chAMER, chEMEA, chAPAC } = state;
    const handleSearch = e => {
        // console.log("HQCountrySearch: ",hqCountryData);
        // console.log("Key Val:", e.target.id);
        const searchVal = e.target.value;
        switch (e.target.id) {
            case "HQ":
                if (searchVal != null || searchVal != "") {
                    const results = hqCountryData.filterValues.filter(country =>
                        country.filter_value.toLowerCase().includes(searchVal.toLowerCase())
                    );
                    setHqCountry(results);
                }
                else {
                    setHqCountry(hqCountryData.filterValues);
                }
                setHqSearchTerm(searchVal);
                break;
            case "DEPLOY":
                if (searchVal != null || searchVal != "") {
                    const results = deployCountryData.filterValues.filter(country =>
                        country.filter_value.toLowerCase().includes(searchVal.toLowerCase())
                    );
                    setDeployCountry(results);
                }
                else {
                    setDeployCountry(deployCountryData.filterValues);
                }
                setDeploySearchTerm(searchVal);
                break;
            case "DEPMETRO":
                if (searchVal != null || searchVal != "") {
                    const results = deployMetroData.filterValues.filter(country =>
                        country.filter_value.toLowerCase().includes(searchVal.toLowerCase())
                    );
                    setDeployMetro(results);
                }
                else {
                    setDeployMetro(deployMetroData.filterValues);
                }
                setDeployMetroSearchTerm(searchVal);
                break;
            case "ENTITY":
                if (searchVal != null || searchVal != "") {
                    const results = entityTypeData.filterValues.filter(value =>
                        value.filter_value.toLowerCase().includes(searchVal.toLowerCase())
                    );
                    setEntityType(results);
                }
                else {
                    setEntityType(deployMetroData.filterValues);
                }
                setEntityTypeSearchTerm(searchVal);
                break;
        }
    };

    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });
        console.log("Event: ", event.filter_value_id);
        console.log("Event only:", event);
        var fid = event.filter_value_id;
        if ((fid >= 109) && (fid <= 111)) {
            let filtered_data = hqRegion.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setHqRegion(filtered_data);
        }
        else if ((fid >= 14 && fid <= 17) || (fid >= 69 && fid <= 78) || (fid == 21)) {
            let filtered_data = hqCountry.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setHqCountry(filtered_data);
        }
        else if ((fid >= 7) && (fid <= 9)) {
            let filtered_data = deployRegion.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setDeployRegion(filtered_data);
        }
        else if ((fid >= 112) && (fid <= 123)) {
            let filtered_data = deployCountry.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setDeployCountry(filtered_data);
        }
        else if ((fid >= 22) && (fid <= 30)) {
            let filtered_data = deployMetro.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setDeployMetro(filtered_data);
        }
        else if ((fid >= 56) && (fid <= 59)) {
            let filtered_data = compSize.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setCompSize(filtered_data);
        }
        else if ((fid >= 39) && (fid <= 47)) {
            let filtered_data = entityType.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setEntityType(filtered_data);
        }
        else if ((fid >= 149) && (fid <= 152)) {
            let filtered_data = salesPrgrm.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setSalesPrgrm(filtered_data);
        }
        else if ((fid >= 61) && (fid <= 63)) {
            let filtered_data = productType.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setProductType(filtered_data);
        }
        else if ((fid >= 155) && (fid <= 156)) {
            let filtered_data = revenueType.map((e) => {
                if (e.filter_value_id === event.filter_value_id) {
                    e.isSelected = !event.isSelected;
                }
                return e;
            })
            setRevenueType(filtered_data);
        }
    };


    const handleRadioChangeHyper = (event) => {
        //setHyperVal(parseInt(event.target.value));
        //setHyperVal(event.target.value);

        console.log("RAdio event:", event.target.value);
        let radio_val = hyperFlag.map((e) => {
            console.log("filter_value_id= ", e.filter_value_id);
            if (e.filter_value_id == event.target.value) {
                console.log("INside radio if")
                e.isSelected = true;
            }
            else {
                console.log("INside radio else");
                e.isSelected = false;
            }
            return e;
        })
        setHyperFlag(radio_val);
        console.log("Radio DAta:", hyperFlag);

    };

    const handleRadioChangeLightHouse = (event) => {
        //setLightHouseVal(event.target.value);
        console.log("RAdio event:", event.target.value);
        let radio_val = lighthouseFlag.map((e) => {
            console.log("filter_value_id= ", e.filter_value_id);
            if (e.filter_value_id == event.target.value) {
                console.log("INside radio if")
                e.isSelected = true;
            }
            else {
                console.log("INside radio else");
                e.isSelected = false;
            }
            return e;
        })
        setLightHouseFlag(radio_val);
        console.log("Radio DAta:", lighthouseFlag);
    };

    const handleRadioChangeSuper = (event) => {
        //setValue(event.target.value);
        console.log("RAdio event:", event.target.value);
        let radio_val = superMetro.map((e) => {
            console.log("filter_value_id= ", e.filter_value_id);
            if (e.filter_value_id == event.target.value) {
                console.log("INside radio if")
                e.isSelected = true;
            }
            else {
                console.log("INside radio else");
                e.isSelected = false;
            }
            return e;
        })
        setSuperMetro(radio_val);
        console.log("Radio DAta:", lighthouseFlag);

    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleApply = () => {
        // console.log("Apply method",hqRegion);
        console.log("Data to be send to API:", data);
        allFiltersCallAPI(data);
    };
    const classes = useStyles();
    const allFiltersCallAPI = (data) => {
        var reqBody = {};
        //let filterOption = [];
        let filterOption = [
            {
                "filter_id": 4,
                "filter_name": "Measure",
                "filter_type": "show as",
                "filter_value_id": 247,
                "filter_value": "As Reported"
                },
                {
                "filter_id": 5,
                "filter_name": "Time Period",
                "filter_type": "show as",
                "filter_value_id": 250,
                "filter_value": "Last 4 Quarter"
                },
                {
                "filter_id": 6,
                "filter_name": "Group by",
                "filter_type": "group by",
                "filter_value_id": 59,
                "filter_value": "Group by Deploy Region"
                }
        ];
        data.forEach((e) => {
            let filterednames = e.filterValues.filter((ele) => ele.isSelected == 1).map((name) => name.filter_value);
            if (filterednames != "") {
        //console.log("Filtered Names:", filterednames);
                //console.log("Filter ID:",e.filterValues.filter((ele) => ele.isSelected == 1).map((name) => name.filter_id));
                // let addNewEle = {
                //     filterOption: [
                //         {
                //             "filter_id": e.filter_id,
                //             "filter_name": e.filter_name,
                //             "filter_type": e.filter_type,
                //             "filter_value": filterednames
                //         }]
                // }
                let addNewEle =
                {
                    "filter_id": e.filter_id,
                    "filter_name": e.filter_name,
                    "filter_type": e.filter_type,
                    "filter_value": filterednames
                }
                filterOption.push(addNewEle);
                // reqBody.filterOption = addNewEle;
                //console.log("addNewEle:", addNewEle);
                let reqBodyNew = {
                    ...reqBody,
                    ...addNewEle
                }
                //console.log("filterOPtion:", reqBodyNew);
                //console.log("filterOption:", filterOption);
                 reqBody = {
                    data_toggle: 'customer',
                    filterOptions: [
                        ...filterOption
                    ]
                };
                // let newObj = {
                //     ...reqBody,
                //     ...filterOption
                // }
                // console.log("NewObj= ",newObj);
        // console.log("REQBODY= ", reqBody);
                //   let filterednames = e.filterValues.filter((ele) => ele.isSelected == 1).map((name) => {
                //       name.filter_value
                //     });
                // if (filterednames != "") {
                //     console.log("Filtered Names:", filterednames);
                //     let addNewEle = {
                //         filterOption: [
                //             {
                //                 "filter_id": e.filterValues.filter_id,
                //                 "filter_name": e.filterValues.filter_name,
                //                 "filter_type": e.filterValues.filter_type,
                //                 "filter_value": filterednames
                //             }]
                //     }
                //     console.log("addNewEle:", addNewEle);
            }
        })
        //let filterednames = data[0].filter((i) => i.is_primary == '0').filterValues.filter((ele) => ele.isSelected == 1).map((name) => name.filter_value);
        // let filterednames = data[0].filterValues.filter((ele) => ele.isSelected == 1).map((name) => name.filter_value);

        // // let reqBody = {data_toggle : 'customer', filterOption : {
        // //     "filter_id": data[0].filter_id,
        // //     "filter_name": data[0].filter_name,
        // //     "filter_type": data[0].filter_type,
        // //     "filter_value": filterednames
        // // }}
        // console.log("Filtered Names:", filterednames);
        // let reqBody = {data_toggle : 'customer'}
        // let addNewEle = {filterOption:[
        //     {"filter_id": data[0].filter_id,
        //     "filter_name": data[0].filter_name,
        //     "filter_type": data[0].filter_type,
        //     "filter_value": filterednames}
        // ]}
        // let addNewEle = {"filter_id": data.filter_id,
        //     "filter_name": data.filter_name,
        //     "filter_type": data.filter_type,
        //     "filter_value": filterednames}
        // // let reqBodyNew = 
        // // let reqBodyNew = reqBody.push(addNewEle);
        // //reqBody.filterOptions = "colo";
        // reqBody.filterOptions = addNewEle;
          console.log("reqbody: ",reqBody);
        fetch(hostname.host + '/analyticalDashboard/getXGrowth', {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            },
            body: reqBody
        })
            .then(response => response.json())
            .then((a) => {
                //console.log("Chart Data=",a);
                console.log("Chart Data============");
            })

    }
    // console.log(dummydata);
    return (
        <div >
            <Button color="transparent" onClick={handleClickOpen} className={classes.button}>
                All Filters
            </Button>
            <Dialog maxWidth="50" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
                <DialogActions>
                    <Button onClick={handleClose} color="transparent" className={classes.button}>
                        Cancel
                                </Button>
                    <Button onClick={handleApply} color="primary" className={classes.applybutton}>
                        Apply
                                </Button>
                </DialogActions>
                <DialogContent>
                    <DialogContentText >
                        <div className={classes.filters_page}>
                            <div className={classes.filters_section}>

                                <div className={classes.checkboxRadioStyle}>

                                    <FormLabel className={classes.filters_label} component="legend">{hqRegionData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {hqRegion.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}

                                    </FormGroup>


                                </div>
                                <div >
                                    <FormLabel className={classes.filters_label} component="legend">{hqCountryData.filter_name}</FormLabel>
                                    {/* <BootstrapInput placeholder="Search for HQ Country" id="bootstrap-input" /> */}
                                    <input id="HQ" placeholder="Search for HQ Country" class="inputstyle" type="text" value={hqSearchTerm} onChange={handleSearch} />
                                    <div className={classes.checkboxRadioStyle}>
                                        <FormGroup >
                                            {hqCountry.map((e) => (
                                                <FormControlLabel style={{ height: '30px' }}
                                                    control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                    label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel className={classes.filters_label} component="legend">{compSizeData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {compSize.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}
                                    </FormGroup>

                                </div>


                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel component="legend" className={classes.filters_label}>
                                        {hyperFlagData.filter_name}
                                    </FormLabel>
                                    {hyperFlag.map((e) => (
                                        <RadioGroup aria-label="Hyper"
                                            name={hyperFlagData.filter_name}
                                            // value={hyperval}
                                            onChange={handleRadioChangeHyper} >

                                            <FormControlLabel id="HYPERSCALE" value={e.filter_value_id} control={<Radio checked={e.isSelected} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>} />
                                        </RadioGroup>
                                    ))}
                                </div>


                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel component="legend" className={classes.filters_label}>
                                        {lighthouseFlagData.filter_name}
                                    </FormLabel>
                                    {lighthouseFlag.map((e) => (
                                        <RadioGroup aria-label="Hyper"
                                            name={lighthouseFlagData.filter_name}
                                            //value={lighthouseval}
                                            onChange={handleRadioChangeLightHouse} >

                                            <FormControlLabel value={e.filter_value_id} control={<Radio checked={e.isSelected} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>} />
                                        </RadioGroup>
                                    ))}
                                </div>

                                <div className={classes.checkboxRadioStyle}>

                                    <FormLabel className={classes.filters_label} component="legend">{deployRegionData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {deployRegion.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}

                                    </FormGroup>

                                </div>
                                <div >
                                    <FormLabel className={classes.filters_label} component="legend">{deployCountryData.filter_name}</FormLabel>
                                    <input id="DEPLOY" placeholder="Search for Deploy Country" class="inputstyle" type="text" value={deploySearchTerm} onChange={handleSearch} />
                                    <div className={classes.checkboxRadioStyle}>
                                        <FormGroup >
                                            {deployCountry.map((e) => (
                                                <FormControlLabel style={{ height: '30px' }}
                                                    control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                    label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.filters_section}>
                                <div >
                                    <FormLabel className={classes.filters_label} component="legend">{deployMetroData.filter_name}</FormLabel>
                                    <BootstrapInput id="DEPMETRO" placeholder="Search for Deploy Metro" value={deployMetroSearchTerm} onChange={handleSearch} />
                                    <div className={classes.checkboxRadioStyle}>
                                        <FormGroup >
                                            {deployMetro.map((e) => (
                                                <FormControlLabel style={{ height: '30px' }}
                                                    control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                    label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div >
                                    <FormLabel className={classes.filters_label} component="legend">{entityTypeData.filter_name}</FormLabel>
                                    <BootstrapInput id="ENTITY" placeholder="Search for Entry Type" value={entityTypeSearchTerm} onChange={handleSearch} />
                                    <div className={classes.checkboxRadioStyle}>
                                        <FormGroup >
                                            {entityType.map((e) => (
                                                <FormControlLabel style={{ height: '30px' }}
                                                    control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                    label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                                />
                                            ))}
                                        </FormGroup>
                                    </div>

                                </div>
                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel className={classes.filters_label} component="legend">{salesPrgrmData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {salesPrgrm.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}

                                    </FormGroup>

                                </div>

                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel component="legend" className={classes.filters_label}>
                                        {superMetroData.filter_name}
                                    </FormLabel>
                                    {superMetro.map((e) => (
                                        <RadioGroup aria-label="Hyper"
                                            name={superMetroData.filter_name}
                                            //value={lighthouseval}
                                            onChange={handleRadioChangeSuper} >
                                            <FormControlLabel value={e.filter_value_id} control={<Radio checked={e.isSelected} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>} />
                                        </RadioGroup>
                                    ))}
                                </div>
                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel className={classes.filters_label} component="legend">{productTypeData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {productType.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}
                                    </FormGroup>

                                </div>
                                <div className={classes.checkboxRadioStyle}>
                                    <FormLabel className={classes.filters_label} component="legend">{revenueTypeData.filter_name}</FormLabel>
                                    <FormGroup >
                                        {revenueType.map((e) => (
                                            <FormControlLabel style={{ height: '30px' }}
                                                control={<Checkbox checked={e.isSelected} onChange={() => handleChange(e)} name={e.filter_value} color="default" size="small" />}
                                                label={<span className={classes.checkboxRadioLabel}>{e.filter_value}</span>}
                                            />
                                        ))}

                                    </FormGroup>

                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                    {/* <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                /> */}
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default DialogsRev
