import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'js-cookie';

import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import './newUserDialog.css';
import {hostname} from '../../hostConfiguration.js';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import './AssignUsers.css';



const useStyles = makeStyles((theme)=> ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 182,
        // marginTop: 28,
        display: 'flex',
        justifyContent: 'space-between',
        display: 'table-caption'
      },
      cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
      addNew: {
        lineHeight: 2,
        fontWeight: 400,
        // backgroundColor: '#C54D39',
        color: 'black',
        borderRadius: '25px',
        width: '118px',
        textAlign: 'center',
        height: '29px',
    },
    dialogContent: {
        width: '500px',
        height: '500px',
    },
    assignedUsers: {
        width: '200px',
        border: '1px solid #ccc',
        height: '400px',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
        overflow: 'auto',
        padding: '15px'
    }
    
}));

export default function AssignUser(props) {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [dataProfiles, setDataProfiles] = React.useState([]);
  const [objectProfiles, setObjectProfiles] = React.useState([]);
//   const [selectedDataProfiles, setSelectedDataProfiles] = React.useState('');
  const [UnAssignedUsers, setUnAssignedUsers] = React.useState([]);
  const [selectedObjectProfiles, setSelectedObjectProfiles] = React.useState([]);
//   const [formData, setFormData] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [message, setMessage] = React.useState('Loading ...');
  let UnAssData = [];
  let formData = {};
  const classes = useStyles();

  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');

  const refreshToken = () => {
    fetch(hostname.host + '/refresh_token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json',
      },
      body: JSON.stringify(refresh_token)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      Cookies.set('access_token', a.data.access_token);
      Cookies.set('refresh_token', a.data.refresh_token);
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }

  useEffect(() => {
    let isMounted = true;
    // checkExpiry();
    fetch(hostname.host + '/admin/users')
        .then(response => response.json())
        .then(a => {
                setUsers(a.data);
                let longest = a.data.map((e)=> e.username).reduce(
                  function (a, b) {
                      return a.length > b.length ? a : b;
                  }
                );
        });
         
          // let divs = document.getElementsByClassName('UsersTransferList');
          // for (var i = 0; i < divs.length; i++) {
          //   divs[i].style.width=((longest.length - 10)+'ch');
          // }
    }, []);

    const assignedUsers = () => {
        let assigned = [];
        let objectProf;
        let dataProf;
        users.forEach(i => {
             objectProf = i.object_profiles.map((e) => e.profile_id);
             dataProf = i.data_profiles ? i.data_profiles['profile_id'] : [];
            //  console.log(objectProf, props.profileId, objectProf.includes(props.profileId) );
             if(objectProf.includes(props.profileId) || dataProf.includes(props.profileId)) {
                 assigned.push(i.user_id);
                //  console.log(i.user_id);
             }
            // console.log(objectProf);
        });
        // console.log(objectProf);
    }

    const handleChange = (event) => {
        // setSelectedDataProfiles(event.target.value);
      };
    // const handleObjectChange = (event) => {
    //     setSelectedObjectProfiles(event.target.value);
    // }
    const handleSnackClose =() =>{
        setSnackbarOpen(false);
    }

  const createUser = () => {
    let userIDs = [];
    // checkExpiry();
    users.forEach((e) => {
        if(selectedUsers.includes(e.username)) {
            userIDs.push(e.user_id);
        }
    })
    formData['userIds'] = userIDs;
    formData['profileId'] = parseInt(props.profileId);
    console.log(formData);
    
    
    fetch(hostname.host + '/admin/user_profile' , {
     method: "POST",
     headers: {
     'Content-type': 'application/json'
    },
    body: JSON.stringify(formData)
    })
    .then((result) => result.json())
    .then((info) => { 
        console.log(info.response.alert.message);
        setMessage(info.response.alert.message);
        
        setSnackbarOpen(true); 
    })
    .catch(error => {
      console.log(error);
      setMessage("Unable to add user"); 
      
      setSnackbarOpen(true); 
    });
    };
    


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const changeField = (event) => {
      setSelectedUsers(event);
    console.log(event);
}

  return (
    <div >
      <Button onClick={handleClickOpen}  color="primary" id="sideButton">
        Assign Users
      </Button>
      <Dialog class="newUserDialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
  <DialogTitle id="newUserForm">{props.profileName}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
      <GridContainer >
        <GridItem  xs={12} sm={12} md={6}>
        <form variant="outlined" className={classes.formControl}>
        <h4>Unassigned Users</h4>
         <Multiselect
      data={users.map(i => {
        let objectProf = i.object_profiles.map((e) => e.profile_id);
        let dataProf = i.data_profile ? [ i.data_profile.profile_id] : [];
        let profileIds = objectProf.concat(dataProf);
        if(!profileIds.includes(parseInt(props.profileId))) {
           return i.username;
        }
   })}
    //   textField='name'
        // value= {[]}
        onChange={changeField}
      caseSensitive={false}
      minLength={3}
      placeholder="Select Users"
      id="AssignedUsers"
      filter='contains'
    />
        </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={6}>
        <form variant="outlined" className={classes.formControl}>
        <h4>Assigned Users</h4>
        <div className={classes.assignedUsers}>
        {
            users.map(i => {
                let objectProf = i.object_profiles.map((e) => e.profile_id);
                let dataProf = i.data_profile ?[ i.data_profile.profile_id] : [];
                if(objectProf.includes(parseInt(props.profileId)) || dataProf.includes(parseInt(props.profileId))) {
                    return (<p key={i.username}>{i.username}</p>);
                }
           })
        }
        </div>
        </form>
        </GridItem>
        </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" id="sideButton">
            Cancel
          </Button>
          <Button onClick={createUser} color="primary" id="createNew">
            Save
          </Button>
        </DialogActions>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </Dialog>
    </div>
  );
}