import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './Tables.css';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    fontFamily: 'Montserrat'
  },
});



export default function Tables(props) {
  const classes = useStyles();

  return (
    // <TableContainer component={Paper} id="tableContainer">
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            {props.tableHead.map((e) => (
            <TableCell id='rowHeading'>{e}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((row) => (
            <TableRow key={row.name}>
              <TableCell id='rowBody' component="th" scope="row">
                {row.a}
              </TableCell>
              <TableCell id='rowBody'  align="right">{row.b}</TableCell>
              <TableCell id='rowBody'  align="right">{row.c}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    // </TableContainer>
  );
}

Tables.propTypes = {
  tableData: PropTypes.array,
  tableHead: PropTypes.array,
  
};
