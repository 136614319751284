import { makeStyles } from '@material-ui/core/styles';
import React,  {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import loader from "assets/img/loader.svg";

import Login from "views/login/login.js";


import RequestAccess from "views/RequestAccess/RequestAccess";
import Cookies from 'js-cookie';
import {hostname} from 'hostConfiguration.js';


export default function SSORedirect() {
    // console.log('inside sample');
    // fetch(hostname.host + '/login')
    // .then(response => response.json())
    // .then(a => {
    //         console.log('SSO Redirect');
    //         if(a.error) {
    //           console.log(a.error);
    //         }

    // })
    window.location.href = hostname.host + '/login';

return (

    <div>
      {/* <a href={hostname.host + '/login'}>login</a> */}
    </div>

    
)


}
