import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import "./AdminBoard.css";
import CustomTabs from '../../components/Tabs/Tabs'
import Home from "./Home/Home";

const anDashData = [
    {
    tabName: 'HOME',
    component: Home,
    location:'AD_HOME',
    key: 1
  },
];

export default function AnalyticsDashboard(props) {

    console.log(props);
    return(
        <div class="analytics_dashboard">
            <CustomTabs data={anDashData}/>
        </div>
        )
}