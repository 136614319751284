import React, {useState, useEffect} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import './Home.css';
import home from "assets/img/home-img.svg";
// import { Table } from "@material-ui/core";
import Tables from "../../components/Tables/Tables.js";
import Announcements from "../../components/announcements/announcements.js";
import Cookies from 'js-cookie';
import {hostname} from '../../hostConfiguration.js';
import jwt_decode from "jwt-decode";

export default function Home() {

    let accToken = Cookies.get('access_token');
    let decoded = jwt_decode(accToken);
    let displayName = decoded.display_name ? decoded.display_name : '';
    console.log(decoded);

    useEffect(() => {
        // const username = Cookies.get('username');
        // const uname = { "username" : username };
        // fetch(hostname.host + '/auth/login', {
        //     method: "POST",
        //     headers: {
        //     'Content-type': 'application/json',
        //    },
        //   body: JSON.stringify(uname)
        //    })
        //       .then(response => response.json())
        //       .then(a => {
        //         if(!a.error) {
        //             Cookies.set('access_token', a.accessToken);
        //             Cookies.set('refresh_token', a.refreshToken);
        //         window.location.href='/app/home'
        //         console.log('loggedIN');
        //         }
      
        //       });
        }, []);

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }


      
      const rows = [
        {a: 'Test Snapshot Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'},
        {a: 'Test Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'},
        {a: 'Test Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'},
        {a: 'Test Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'},
        {a: 'Test Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'},
        {a: 'Test Snapshot 1', b: 'Snapshot Builder', c: '02/25/2020'}
      ];
      const head = ['Report name', 'Product', 'Created Date']

    return(
        <div class="home_container">
            <div class="home_upper">
                <div class="home_greeting">
                    <h3>Welcome back {displayName} !</h3>
                    <div>Do you waste time organizing sticky notes, searching your email and apps for to-dos, and figuring out what to work on first? Then you need one solution to all problems</div>

                </div>
                <div class="homeImgContainer">
                    <img class="home_img" src={home}></img>
                </div>
                <div class="site_statistics">
                    <div class="card_header">SITE STATISTICS</div>
                    <div class="statistics_body">
                        <div class="stats_col"></div>
                        <div class="stats_col"></div>
                        <div></div>
                    </div>

                </div>
            </div>
            <div class="home_bottom">
                <div class="card_body">
                    <div class="card_header">ANNOUNCEMENTS</div>
                    <div class='card_table'>
                        <Announcements />
                    </div>
                </div>
                <div class="card_body">
                    <div class="card_header">RECENTLY CREATED REPORTS</div>
                    <div class="card_table">
                    <Tables tableData = {rows} tableHead ={head} />

                    </div>
                </div>
                <div class="card_body">
                    <div class="card_header">RECENTLY VIEWED REPORTS</div>
                    <div class="card_table">
                    <Tables tableData = {rows} tableHead ={head} />

                    </div>
                </div>

            </div>
        </div>
        )
}