/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/views/iconsStyle.js";

const useStyles = makeStyles(styles);

export default function Icons() {
  const classes = useStyles();
  return (
    <div>
    <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          
            <h1>Bespoke reports at your fingertips</h1>
            
        </GridItem>
       
        
       
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          
           <p>Dodge the repetitive process of slide productionwith our built in templates and pre-loaded data sets,ensuring speed and quality of your analysis.</p>
           <Button color="primary" round>Use the Wizard</Button>
           <Button color="primary" round>Create a Blank Report</Button>
        </GridItem>
       
      
      </GridContainer>

      </div>
  );
}
