import React, {useState, useEffect, useRef} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Icon from "@material-ui/core/Icon";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import './newUserDialog.css';
import {hostname} from '../../hostConfiguration.js';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';




const useStyles = makeStyles((theme)=> ({
    root: {
      marginTop: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 182,
        marginTop: 28,
        display: 'flex',
        justifyContent: 'space-between',
        color: '#748A9D',
        fontFamily: 'Montserrat',


      },
      cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
      addNew: {
        lineHeight: 2,
        fontWeight: 400,
        backgroundColor: '#C54D39',
        color: 'white',
        borderRadius: '25px',
        width: '118px',
        textAlign: 'center',
        height: '29px',
    },
    paper: {
      width: 219,
      height: 230,
      overflow: 'auto',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: 'none'
    },
    
}));


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


export default function NewUserDialog() {
  const [open, setOpen] = React.useState(false);
  const [dataProfiles, setDataProfiles] = React.useState([]);
  const [objectProfiles, setObjectProfiles] = React.useState([]);
  const [selectedDataProfiles, setSelectedDataProfiles] = React.useState('');
  const [selectedObjectProfiles, setSelectedObjectProfiles] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [message, setMessage] = React.useState('Loading ...');
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
//    React.useState({
//     "user_name" : '',
//     "display_name" : '',
//     "email" : '',
//     "first_name" : '',
//     "last_name" : '',
//     "department" : '',
//     "title" : '',
//     "data_profile" : '',
//     "object_profiles" : [],
//   });
  const classes = useStyles();

  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const componentRef = useRef(null);

  const handleToggle = (value) => () => {
    if (document.getElementById("transferlistItem"+ value).style.backgroundColor == "rgb(251, 244, 242)"){
      document.getElementById("transferlistItem"+ value).style.backgroundColor = "";
    }else document.getElementById("transferlistItem"+ value).style.backgroundColor = "#fbf4f2";
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };


  const refreshToken = () => {
    let refreshTok = {"refreshToken" : refresh_token};
    fetch(hostname.host + '/auth/refresh-token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json',
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      
      if(!a.error) {
        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
      window.location.reload();
      }
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }


  useEffect(() => {
    // checkExpiry();
    fetch(hostname.host + '/admin/profiles', {
      method: "GET",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${access_token}`,
     }
     })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            setObjectProfiles(a.data.object_profiles);
            setLeft(a.data.object_profiles.map((e) => e.profile_name));
            setDataProfiles(a.data.data_profiles);
      
          }
        });
    }, []);

    const updateDOM = (el) => {
      // console.log(el, el.style);
      componentRef.current = el;

      let longest = objectProfiles.map((e)=> e.profile_name).reduce(
      function (a, b) {
        return a.length > b.length ? a : b;
      }
      );
    let divs = document.getElementsByClassName('newUserTransferList');
      for (var i = 0; i < divs.length; i++) {
      divs[i].style.width=((longest.length)+'ch');
    }
      
    }

    const customList = (items) => (
      <Paper className={classes.paper}>
        <List dense component="div" role="list" ref={updateDOM} class="newUserTransferList">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;
  
            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)} id={"transferlistItem"+ value}>
                {/* <ListItemIcon class='checkboxIcon'>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    class="checkboxImg"
                  />
                </ListItemIcon> */}
                <ListItemText id={labelId} primary={value} class='transferListLabel' />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );


    const handleChange = (event) => {
        setSelectedDataProfiles(event.target.value);
      };
    const handleObjectChange = (event) => {
        setSelectedObjectProfiles(event.target.value);
    }
    const handleSnackClose =() =>{
        setSnackbarOpen(false);
    }

  const createUser = () => {
    const profile_ids = [];
    const userDataProfile = document.getElementById('data_profile').innerHTML;
    const selectedRow = dataProfiles.filter((e) => e.profile_name == userDataProfile)[0];
    const selectedProfile = selectedRow ? selectedRow.profile_id : '';
    // const ObjProfile = document.getElementById('object_profiles').innerHTML;
    // const objProfileIds = ObjProfile.split(', ');
    // for (let a =0; a < objProfileIds.length; a++) {
    //       objectProfiles.forEach((i) =>{ 
    //         if(i.profile_name == objProfileIds[a]) {
    //             profile_ids.push(i.profile_id);
    //             console.log(i.profile_name);
    //         }
    //     });
    // }

    formData['data_profile'] = selectedProfile;
    let obj = objectProfiles.filter((e)=> right.includes(e.profile_name)).map((e)=>e.profile_id);
    formData['object_profiles'] = obj;
    console.log(formData, profile_ids);

    if (formData.username && formData.display_name && formData.department && formData.email ) {
      // checkExpiry();
    fetch(hostname.host + '/admin/users' , {
     method: "POST",
     headers: {
     'Content-type': 'application/json',
     'Authorization': `Bearer ${access_token}`,
    },
    body: JSON.stringify(formData)
    })
    .then((result) => result.json())
    .then((info) => { 
      if (info.error && info.error.status == 401 && info.error.message == "jwt expired") {
        console.log(info.error.status);
        refreshToken();
      } else {
        console.log(info.response.alert.message);
        setMessage(info.response.alert.message);
        
        setSnackbarOpen(true); 
        window.location.reload();
      }
    })
    .catch(error => {
      console.log(error);
      setMessage("Unable to add user"); 
      
      setSnackbarOpen(true); 
    });
    } else {
      setMessage("Please fill all the required fields"); 
      
      setSnackbarOpen(true); 

    }
    
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createAnother = () => {
    document.querySelectorAll("input").forEach(
      input => (input.value = "")
    );
    setSelectedObjectProfiles([]);
    setSelectedDataProfiles('');
  }

  const changeField = event => {
    formData[event.target.id] = event.target.value;
    setFormData(formData);
}

  return (
    <div >
      <p onClick={handleClickOpen} className={classes.addNew}>
      Create New
      </p>
      <Dialog class="newUserDialog" open={open} onClose={handleClose} scroll='body' aria-labelledby="form-dialog-title" >
        <DialogTitle id="newUserForm">CREATE NEW USER</DialogTitle>
        <DialogContent id="newUserDialogBody">
        {/* <Card> */}
    {/* <CardBody> */}
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={12}>
        <input
        style={{ display: "none" }}
        id="contained-button-file"
        type="file"
        />
        <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
        Upload
        </Button>
        </label>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
          <div>Username*</div>
          <TextField
            // label="Username"
            variant="outlined"
            id="username"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>Display name*</div>
          <TextField
            // label="Display Name"
            variant="outlined"
            id="display_name"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>Email*</div>
          <TextField
            // label="Email address"
            id="email"
            variant="outlined"
            onChange = {changeField}
            type = 'email'
            required
          />
          </form>
        </GridItem>
      
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>First Name</div>
          <TextField
            // label="First Name"
            id="first_name"
            variant="outlined"
            onChange = {changeField}
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>Last Name</div>
          <TextField
            // label="Last Name"
            id="last_name"
            variant="outlined"
            onChange = {changeField}
            
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>Department*</div>
          <TextField
            // label="Department"
            id="department"
            variant="outlined"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <form className={classes.formControl}>
        <div>Title</div>
          <TextField
            // label="Department"
            id="title"
            variant="outlined"
            onChange = {changeField}
            required
          />
          </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl}>
        <div>Data Profile*</div>
        {/* <InputLabel id="demo-simple-select-filled-label">Age</InputLabel> */}
        <Select
          labelId="Data Profile"
          id="data_profile"
        //   multiple
          value={selectedDataProfiles}
          onChange={handleChange}
        // input={<Input />}
        // renderValue={(selected) => selected.join(', ')}
        >
          {dataProfiles.map((i) => (
          <MenuItem key={i.profile_name} value={i.profile_name}>
              {/* <Checkbox checked={selectedProfiles.indexOf(i.profile_name) > -1} />
              <ListItemText primary={i.profile_name} /> */}
              {i.profile_name}
            </MenuItem>
          ))}
        </Select>
        </form>
        </GridItem>
        <GridItem  xs={12} sm={12} md={12}>
        <form variant="outlined" className={classes.formControl} id="objectProfile">
        <div>Object Profiles*</div>
        {/* <Select
          labelId="Object Profiles"
          id="object_profiles"
          multiple
          value={selectedObjectProfiles}
          onChange={handleObjectChange}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        >
          {objectProfiles.map((i) => (
          <MenuItem key={i.profile_name} value={i.profile_name}>
              <Checkbox checked={selectedObjectProfiles.indexOf(i.profile_name) > -1} />
              <ListItemText primary={i.profile_name} />
            </MenuItem>
          ))}
        </Select> */}
        <Grid container spacing={2}  alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
        </form>
        </GridItem>
      </GridContainer>
    {/* </CardBody> */}
  {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" id="sideButton">
            Cancel
          </Button>
          <Button onClick={createAnother} color="primary" id="sideButton">
            Create Another
          </Button>
          <Button onClick={createUser} color="primary" id="createNew">
            Create User
          </Button>
        </DialogActions>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message= {message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </Dialog>
    </div>
  );
}