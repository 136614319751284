/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import app from "app.js";
import Home from "views/Home/Home.js";
import SecuredRoute from "./securedRoute.js";
import './index.css';
import TimeAgo from 'javascript-time-ago';

import Login from "views/login/login.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";

import en from 'javascript-time-ago/locale/en';
import RequestAccess from "views/RequestAccess/RequestAccess";
import Cookies from 'js-cookie';
import Main from "main.js";
import {hostname} from 'hostConfiguration.js';
import SSORedirect from "views/SSORedirect.js";

TimeAgo.addDefaultLocale(en)
const hist = createBrowserHistory();

const isLoggedIn = () => {
  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');
  if (access_token) {
    return true
  } else {
    return false
  }
}

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/analytics-dashboard" component={Main} />
      <Route  path="/app" component={Main} />
      {/* <Route path="/home" component={Home} /> */}
      <Route exact path="/userLogin" component={Login} />
      {/* <Route exact path="/" render={() => {
                 fetch(hostname.host + '/login')
                 .then(response => response.json())
                 .then(a => {
                         console.log('SSO Redirect');
                         if(a.error) {
                           console.log(a.error);
                         }
         
                 });
            }
        }/> */}

      <Route exact path="/" component={SSORedirect} />
      <Route exact path="/requestAccess/:id" component={RequestAccess} />
      {/* <Redirect from="/" to="/login" /> */}

    </Switch>
  </Router>,
  document.getElementById("root")
);
