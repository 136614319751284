import { makeStyles } from '@material-ui/core/styles';
import React, {useState, useEffect} from "react";
import {hostname} from '../../hostConfiguration.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ReactTimeAgo from 'react-time-ago';
import events from "../../assets/img/announcements/events.svg";
import notice from "../../assets/img/announcements/notice.svg";
import updates from "../../assets/img/announcements/updates.svg";
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: 'Montserrat',
    padding: '0px',
    // maxWidth: '36ch',

    backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'yellow'
  },
  inline: {
    display: 'inline',
    fontSize: '11.2px',
    color: '#748A9D',
    fontFamily: 'Montserrat',
    textTransform: 'upperCase'
    
  },
  listBody: {
    display: 'block',
    color: '#737373',
    fontFamily: 'Montserrat',
    // fontSize: '16px',
  },
  outerList: {
    marginTop: '0px',
    fontFamily: 'Montserrat',
    // paddingBottom: '0.2px'
    // backgroundColor: 'red'
  },
  time: {
    fontSize: '9.2px',
    fontFamily: 'Montserrat',
    textTransform: 'upperCase'
  },
  
  // ':last-child': {
  //   divider: {
  //     display: 'none',
    
  //   }
  // }
}));

export default function Announcements() {
  const classes = useStyles();
  const [announcements, setAnnouncements] = React.useState([]);

  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');

  const refreshToken = () => {
    let refreshTok = {username : refresh_token}
    fetch(hostname.host + '/auth/refresh-token' , {
      method: "POST",
      headers: {
      'Content-type': 'application/json',
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      console.log(a.data);
      if(!a.error) {

        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
        window.location.reload();
      }
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }

  useEffect(() => {
    fetch(hostname.host + '/admin/announcements', {
      method: "GET",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${access_token}`
      }
      })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
          setAnnouncements(a.data);
          console.log(a.data);
          }

        });
    }, []);
    const images = ((e) => {
      switch (e) {
        case 'NOTICE':
          return <img src={notice} />;
        case 'EVENTS':
          return <img src={events} />;
        case 'UPDATES':
          return <img src={updates} />;
      }
    })

  return (
    <List className={classes.root} >
      {announcements.map((e, index) => (
        <div>
      <ListItem alignItems="flex-start" style={{padding: '3px 5px 1px 5px'}}>
      {/* <ListItem > */}
        <ListItemAvatar>
        {images(e.announcement_type)}

        </ListItemAvatar>
        <ListItemText className={classes.outerList}
          primary=
          {<React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
             {e.announcement_type}
            </Typography>
          </React.Fragment>}
          secondary={
            <React.Fragment className={classes.listBody}>
              <Typography
                component="span"
                variant="body2"
                className={classes.listBody}
                color="textPrimary"
              >
               {e.announcement}
              </Typography>
              <ReactTimeAgo className={classes.time} date={e.created_on}/>
            </React.Fragment>
          }  
        />
      </ListItem>
      
          {announcements.length - 1 === index ?
            <div></div> :
            <Divider className={classes.divider} variant="fullWidth" component="li" />}
      
      </div>
      
      )
      )}
      
     
    </List>
  );
}
