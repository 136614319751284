/*eslint-disable*/
import React, {useState, useEffect} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Logout from "assets/img/icons/logout.svg";
import Icon from "@material-ui/core/Icon";
import Cookies from 'js-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import {hostname} from '../../hostConfiguration.js';
import Blocks from "assets/img/icons/blocks.svg";

import './Sidebar.css';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const refresh_token = Cookies.get('refresh_token');
  const access_token = Cookies.get('access_token');
  const [menu, setMenu] = React.useState([]);
  const [accessMenu, setAccessMenu] = React.useState([]);
  const [confirmOut, setConfirmOut] = React.useState(false);

  const refreshToken = () => {
    let refreshTok = {"refreshToken" : refresh_token};
    fetch(hostname.host + '/auth/refresh-token', {
      method: "POST",
      headers: {
      'Content-type': 'application/json'
      },
      body: JSON.stringify(refreshTok)
    }).then(response => response.json())
    .then(a => {
      if(!a.error) {
        Cookies.set('access_token', a.accessToken);
        Cookies.set('refresh_token', a.refreshToken);
      window.location.reload();
      }
    })

  }

  // const checkExpiry = () => {
  //   let dateNow = new Date();
  //   if (access_expiry < dateNow.getTime()) {
  //     refreshToken();
  //   }
  // }

  const logoutUser =()  =>{
   setConfirmOut(true);
  }

  const cancelOut = () => {
    setConfirmOut(false);
  }

  const confirmLogout = () => {
    let refreshTokn = {"refreshToken" : refresh_token};
    fetch(hostname.host + '/auth/logout', {
     method: "DELETE",
     headers: {
      'Content-type': 'application/json'
      },
     body: JSON.stringify(refreshTokn)
     })
      .then(response => response.json())
      .then(a => {
        console.log(a);
      })
      // localStorage.clear();
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');
      Cookies.remove('username');
      window.location.href = '/userLogin?logout';
  }

  useEffect(() => {
    // const username = Cookies.get('username');
    // const uname = { "username" : username };
    // fetch(hostname.host + '/auth/login', {
    //   method: "POST",
    //   headers: {
    //   'Content-type': 'application/json',
    //  },
    // body: JSON.stringify(uname)
    //  })
    //     .then(response => response.json())
    //     .then(a => {
    //       if(!a.error) {
    //           Cookies.set('access_token', a.accessToken);
    //           Cookies.set('refresh_token', a.refreshToken);
    //       // window.location.href='/app/home'
    //       console.log('loggedIN', Cookies.get('access_token'));
    //       }

    //     });
    fetch(hostname.host + '/module/left-menus', {
      method: "GET",
      headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${Cookies.get('access_token')}`
      }
     })
        .then(response => response.json())
        .then(a => {
          if (a.error && a.error.status == 401 && a.error.message == "jwt expired") {
            console.log(a.error.status);
            refreshToken();
          } else {
            console.log(a);
            let menu = a.data.map((e) => e.access_object_name);
            setMenu(a.data);
            let arr = [];
            routes.forEach((e) => {
              a.data.forEach((api) => {
                if (api.access_object_name.replace(/\s/g, '').toLowerCase() == e.name.replace(/\s/g, '').toLowerCase() 
                && (api.access_object_name != 'Admin Console')){
                  arr.push({
                    path: e.path,
                    layout: e.layout,
                    icon: api.access_object_icon,
                    name: api.access_object_name
                  })
                }
              })
             
            })
            setAccessMenu(arr);
          }
        }).catch((e) => {
          console.log(e);
        })
    }, []);

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, logoText, routes } = props;

  // const TopRoutes = ['Executive Cockpit', 'SnapShot Builder', 'Account Navigator', 'Self-Service Reports', 'Analytics Dashboard']
  const TopRoutes = menu.filter((e) => e != 'Admin Console');
  const BottomRoutes = ['App Marketplace', 'Settings', 'Help Center'];
  if (menu.map((e) => e.access_object_name).includes('Admin Console')) {
    BottomRoutes.splice( 0, 0, 'Admin Console' );
  }
  var Toplinks = (
    <List className={classes.list} id="topLinkList">
      {accessMenu.map((prop, key) => {
        var listItemClasses;
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
            id={prop.name}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <img
                  className={classNames(classes.itemIcon, whiteFontClasses) } 
                  src={prop.icon}
                >
                </img>
              ) : (
                (prop.icon ? 
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                  :
                  
                  <img src={Blocks}  className={classNames(classes.itemIcon, whiteFontClasses)}/>
                  )
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
              // }})
      // }
      })}
    
    
    </List>
  );

  var Bottomlinks = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if(BottomRoutes.includes(prop.name)) {
        var listItemClasses;
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <img
                  className={classNames(classes.itemIcon, whiteFontClasses) } 
                  src={prop.icon}
                >
                </img>
              ) : (
                (prop.icon ? 
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
                :
                
                <img src={Blocks}  className={classNames(classes.itemIcon, whiteFontClasses)}/>
                )
                
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      }})}
    
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <a
        href="/app/home"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        // target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo"  />
        </div>
        {/* {logoText} */}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {Toplinks}
            {Bottomlinks}
            <span>Logout</span>
          </div>
            <div
              className={classes.background}
              style={{ backgroundColor: "#39484C" }}
            />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {Toplinks}
            <div>
            {Bottomlinks}
            <div class="logout_link" onClick={logoutUser}>
              <img src={Logout} ></img>
              Logout
              </div>
            </div>
           
            </div>
            <div
              className={classes.background}
              style={{ backgroundColor: "#39484C" }}
            />
        </Drawer>
      </Hidden>
      <Dialog aria-labelledby="customized-dialog-title" open={confirmOut}>
        <DialogContent dividers>
          <Typography gutterBottom>
          ARE YOU SURE YOU WANT TO LOGOUT?
          </Typography>
         
        </DialogContent>
        <DialogActions>
        <Button  onClick={cancelOut} >
            NO
          </Button>
          <Button  onClick={confirmLogout} id="createNew" color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
